import React from 'react'
import './App.css';
import {BrowserRouter as Router,Switch,Route } from 'react-router-dom'
import {Nav} from './Components/Nav'
import { Body } from './Components/Body';
import {History} from './Components/History'
import {Report} from './Components/Report'
import {MenuControl} from './Components/MenuControl'
import {StaffControl} from './Components/StaffControl'
import {AuthProvider} from './Components/Auth'
import PrivateRoute from './Components/PrivateRoute'
import Login from './Components/Login'
import {Expenses} from './Components/Expenses'
import {Phone} from './Components/Phone'

function App() {
  return (
    <AuthProvider>
    <Router>
      <div id="mainpage">
      <div id="p1" style={{ backgroundColor: "#ddd"}} >
      <Nav/>
      </div>
      <div id="p2">
      <Switch>
        <PrivateRoute exact path="/" component={Body} ></PrivateRoute>
        <Route exact path="/his" component={History}></Route>
        <Route exact path="/report" component={Report}></Route>
        <Route exact path="/menucontrol" component={MenuControl}></Route>
        <Route exact path="/staffcontrol" component={StaffControl}></Route>
        <Route exact path="/expenses" component={Expenses}></Route>
        <Route exact path="/login" component={Login}></Route>
        <Route exact path="/phone" component={Phone}></Route>
      </Switch>
      </div>
      </div>
    </Router>
    </AuthProvider>
  );
}

export default App;
