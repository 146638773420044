import React,{ useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import IconButton from '@material-ui/core/IconButton';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';
// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import "firebase/firestore";
import firebase from 'firebase/app'
import { ModalDelete } from './HistoryTable';

var moment = require('moment');
moment().format();

// const useStyles = makeStyles((theme) => ({
//     root2: {
//         marginTop: 10,
//         width: '96%',
//         maxWidth: 600,
//         backgroundColor: theme.palette.action.selected,
//         borderRadius: 10,
//         paddingLeft: 10,
//     },
//     root: {
//         '& > *': {
//             margin: theme.spacing(1),
//         },
//     },
//     formControl: {
//         fontsize: 20,
//         margin: theme.spacing(0),
//         minWidth: 50,
//     },
//     selectEmpty: {
//         marginTop: theme.spacing(2),
//     },
//     root3: {
//         '& > *': {
//             margin: theme.spacing(2),
//         },
//     },
// }));

const db = firebase.firestore();

  



export function Expenses(){
  //thông tin đăng nhập
  var user = firebase.auth().currentUser;


    //select date
  const [startDate3, setStartDate3] = useState(new Date());
  const [date3, setDate3] = useState([])
    //select category
    const [cate, setCategory] = useState("")   
    //input new expense
    const [price,setPrice] = useState("")
    //input note for new expense
    const [note,setNote] = useState("")
    //hiển thị chi phí firebase
  const [exp,setFbExp] = useState([])

    //xóa mục chi
  const [selectedId,setSelectedId] = useState('')
  const [show, setShow] = useState(false);
  const [pass, setPass] = useState('')

  const handleClose = () => setShow(false);
  const handleShow = (index) => {
    setShow(true)
    setSelectedId(index)
    console.log(index)
  }
  const handleChangePass = (e) => {
    console.log(e.target.value)
    setPass(e.target.value)
  }
  const handleCheckPass = () => {
    handleClose()
    if(pass === "khumco"){
      deleteitem(selectedId)
    } else {
        alert("Cần nhập đúng mật khẩu để xóa")
    }
  }
  function deleteitem(id){
    console.log(id)
    db.collection("expense").doc(id).delete().then(function() {
      setPrice(0)
      console.log("Document successfully deleted!");
  }).catch(function(error) {
      console.error("Error removing document: ", error);
  });
  }  
  useEffect(()=>{
    let fbexpense = []
    let fbid=[]
    db.collection("expense").where("date","==",date3)
    .get()
      .then((onSnapshot) => {
        onSnapshot.forEach((doc) => {
          fbid.push(doc.id)
          fbexpense.push(doc.data())
        })
        for(let i=0;i<fbid.length;i++){
          fbexpense[i].id=fbid[i]
        }
        function compare(a, b) {
          const bandA = a.date;
          const bandB = b.date;
        
          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
      }
      fbexpense.sort(compare)
        setFbExp(fbexpense)
      })
  },[price,date3,selectedId])
  //CÀI ĐẶT XEM HÓA ĐƠN THEO NGÀY
  // function handleChange1(value) {
  //   const valueOfInput = moment(value).format("DD/MM/YYYY");
  //   let ad = valueOfInput.split("/")
  //   console.log(valueOfInput)
  //   setStartDate1(value)
  //   setDate1(ad)
  // }
  // function handleChange2(value) {
  //   const valueOfInput = moment(value).format("DD/MM/YYYY");
  //   let ad = valueOfInput.split("/")
  //   console.log(valueOfInput)
  //   setStartDate2(value)
  //   setDate2(ad)
  // }
  function handleChange3(value) {
    const valueOfInput = moment(value).format("DD/MM/YYYY");
    let ad = valueOfInput
    console.log(valueOfInput)
    setStartDate3(value)
    setDate3(ad)
  }
  function handleCategory(event){
    console.log(event.target.value)
    setCategory(event.target.value)
}
  function handleInput(event){
    console.log(event.target.value)
    setPrice(event.target.value)
  }
  function handleNote(event){
    console.log(event.target.value)
    setNote(event.target.value)
  }
  function newExpense(){
    console.log(price,date3,cate)
    //add firebase
    if (user.email === "phat.phamqt@gmail.com" || user.email === "cn2@gmail.com") {
      db.collection("expense").add({
          branch: 2,
          date: date3,
          category: cate,
          price:price,
          note:note,
          createdAt : firebase.firestore.FieldValue.serverTimestamp()
      })
          .then(function (docRef) {
              setPrice(0)
              console.log("Document written with ID: ", docRef.id);
              db.collection("expense").doc(docRef.id)
                  .onSnapshot(function (doc) {
                      console.log("Current data: ", doc.data());
                  });

          })
          .catch(function (error) {
              console.error("Error adding document: ", error);
          });
  }
  if (user.email === "cn1@gmail.com") {
      db.collection("expense").add({
          branch: 1,
          date:date3,
          category:cate,
          price:price,
          note:note,
          createdAt : firebase.firestore.FieldValue.serverTimestamp()
      })
          .then(function (docRef) {
              console.log("Document written with ID: ", docRef.id);
              db.collection("expense").doc(docRef.id)
                  .onSnapshot(function (doc) {
                      console.log("Current data: ", doc.data());
                  });

          })
          .catch(function (error) {
              console.error("Error adding document: ", error);
          });
  }
  }


    return <div>
        <div id="datefilter">
      &nbsp;
      <label for="start">Ngày chi : </label>
      <DatePicker id="start" selected={startDate3} onChange={(value) => handleChange3(value)} />
      &nbsp; &nbsp;
            {/* <FormControl className={classes.formControl}> */}
        <label for="demo-simple-select">Phân loại: </label>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={cate}
                    onChange={(e) => { handleCategory(e) }}   
                >
                    <MenuItem value="Điện">Điện</MenuItem>
                    <MenuItem value="Nước">Nước</MenuItem>                    
                    <MenuItem value="Nhà">Nhà</MenuItem>
                    <MenuItem value="Nhập hàng">Nhập hàng</MenuItem>
                    <MenuItem value="Nhu yếu phẩm">Nhu yếu phẩm</MenuItem>
                    <MenuItem value="Cơm trưa">Cơm trưa</MenuItem>
                    <MenuItem value="Lương">Lương</MenuItem>                                                                                
                </Select>
            {/* </FormControl> */}
      &nbsp; &nbsp;
        <label for="priceinput">Tiền: </label>
        <input onChange={e=>{handleInput(e)}} style={{width:"70px"}} id="priceinput" type="text"></input>
      &nbsp; &nbsp;
        <label for="noteinput">Ghi chú</label>
        <input onChange={e=>{handleNote(e)}} id="noteinput" type="text" placeholder="số lượng, tên cụ thể"></input>
      &nbsp;&nbsp; 
        <Button variant="contained" color="primary" size="small" onClick={newExpense}>Thêm mới</Button>
      <br></br>
      <br></br>
        {/* <lable for="start">Từ : </lable>
        <DatePicker id="start" selected={startDate1} onChange={(value) => handleChange1(value)} />
        <label for="end"> Đến : </label>
        <DatePicker id="end" selected={startDate2} onChange={(value) => handleChange2(value)} />
        <IconButton onClick={getdata}><SearchIcon /></IconButton> */}
        <table id="historytable">
        <thead>
        <tr>
          <td>Ngày</td>
          <td>Loại</td>
          <td>Ghi chú</td>
          <td>Tổng tiền</td>
        </tr>
        </thead>
        <tbody>
        {exp.map((ex,index)=>(
          <tr key={ex.id}>
            <td>{ex.date} </td>
            <td>{ex.category} </td>
            <td>{ex.note} </td>
            <td><div id="expenselist">{ex.price} <IconButton onClick={()=>{handleShow(ex.id)}} size="small"><HighlightOffIcon size="small" color="secondary"></HighlightOffIcon></IconButton> </div> </td>

          </tr>
        ))}
        </tbody>
      </table>
    </div>
      <ModalDelete
        show={show}
        handleClose={() => { handleClose() }}
        handleChangePass={(e) => { handleChangePass(e) }}
        handleCheckPass={() => { handleCheckPass() }}
      ></ModalDelete>
    </div>
}