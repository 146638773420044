
export const menu=[
    {
        num:1,
        category:"NAM",
        name:"Cắt Nam",
        type:"cut",
        fix:10,
        price:80,
        called:0
    },
    {
        num:2,
        category:"NU",
        name:"Cắt Nữ",
        price:150,
        type:"cut",
        called:0,
        fix:10,
    }, {
        num:3,
        category:"NU",
        name:"Cắt Gội Nữ",
        price:230,
        called:0,
        type:"cut",
    }, {
        num:4,
        category:"NU",
        name:"Duỗi",
        price:700,
        called:0,
        type:"che",
        fix:20
    },  {
        num:5,
        category:"NAM",
        name:"Cạo mặt",
        price:10,
        called:0,
        type:"cut",
        fix:2
    }, {
        num:6,
        category:"NU",
        name:"Gội đầu",
        price:80,
        called:0,
        type:"other",
        fix:10,
    } ,{
        num:6,
        category:"NU",
        name:"Uốn",
        price:600,
        called:0,
        type:"che",
        fix:20
    }
]


export const employee=[
    {
        id:0,
        name:"trống",
        type:"fix",
        percent:0,  
    },
    {
        id:1,
        name:"An",
        percent:0,
        type:"fix",
    }, {
        id:2,
        name:"Dương",
        percent:20,
        type:"fix"
    }, {
        id:3,
        name:"Bình",
        percent:20,
        type:"partner",
    }, {
        id:4,
        name:"Hiền",
        percent:20,
        type:"partner",
    },{
        id:5,
        name:"Tuyền",
        percent:10,
        type:"fix"
    },{
        id:5,
        name:"Khanh",
        percent:20,
        type:"partner",
    },{
        id:6,
        name:"Tin",
        percent:20,
        type:"fix",
    }
]
