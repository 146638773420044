import React, { useState, useEffect,useRef } from 'react'
import "firebase/firestore";
import firebase from 'firebase/app'
import "date-fns";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import app from '../Config/firebase.config'

import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import MenuIcon from '@material-ui/icons/Menu';
import { getMonth, getYear } from "date-fns";
import Paper from '@material-ui/core/Paper';
import {
  Chart,
  PieSeries,
  Title,
} from '@devexpress/dx-react-chart-material-ui';

import { Animation } from '@devexpress/dx-react-chart';

const db = firebase.firestore();

var moment = require('moment');
moment().format();

let samedayreportt
let lichsuchi=[]
let tonghoadon=[]
let tongthe=[]
let tongchi=[]
let tongchietkhau=[]
let innv=[]
const reducer = (accumulator, currentValue) => accumulator + currentValue;


export function Phone(){
    //bật tắt xem chi phí
    const [show, setShow] = useState("none")
    const [selectedDate, setSelectedDate] = React.useState(new Date() )
    const [date1,setDate1]=useState(new Date())
    const [date2,setDate2]=useState(new Date())
    const [employ,setEmploy] = useState([])
    const [his, setHis] = useState([])
    const [samedayhis,setSameDayHis] =useState([])
    const [samedayreport,setSameDayReport]=useState([])
    const [totalRev,setTotalRev]=useState([])
    const [totalCre,setTotalCre]=useState([])
    const [totalShare,setTotalShare]=useState([])
    const [expense,setExpense] =useState([])
    const[totalexpense,setTotalExp]=useState("")
    const[ex,setEx]=useState("")
    let torev=[0];
    let tocre=[0]
    let tosha=[0];
    let receipthis = []
    let idhis = []
    let nhanvien = []
    let exx=[] 
    //lay data fb employee
    useEffect(()=>{
      let fbemp=[]
      db.collection("staff")
      .get()
      .then((onSnapshot)=>{
          onSnapshot.forEach((doc)=>{
              fbemp.push(doc.data())
          })
          function compare(a, b) {
              const bandA = a.num;
              const bandB = b.num;
            
              let comparison = 0;
              if (bandA > bandB) {
                comparison = 1;
              } else if (bandA < bandB) {
                comparison = -1;
              }
              return comparison;
          }
          fbemp.sort(compare)    
          setEmploy(fbemp)
      })
    },[])
    //lay data fb employee xong
    //useEffect phan tich data lay ra chiet khau moi nv
    useEffect(()=>{
      let totalshare=[]
      console.log(samedayhis)
      for(let i=0;i<employ.length;i++){
          totalshare.push({
              name:employ[i].name,
              sale:0
          })
      }
      console.log(totalshare)
      for(let i=0;i<samedayhis.length;i++){
          for(let j=0;j<samedayhis[i].services.length;j++){
              for(let k=0;k<samedayhis[i].services[j].chietkhau.length;k++){
                  for(let l=0;l<totalshare.length;l++ ){
                      if(totalshare[l].name===samedayhis[i].services[j].chietkhau[k].name){
                          totalshare[l].sale+=samedayhis[i].services[j].chietkhau[k].tienchietkhau
                      }
                  }
              }
              for(let k=0;k<samedayhis[i].services[j].help.length;k++){
                  for(let l=0;l<totalshare.length;l++ ){
                      if(totalshare[l].name===samedayhis[i].services[j].help[k].name){
                          totalshare[l].sale+=samedayhis[i].services[j].help[k].tienchietkhau
                      }
                  }
              }
          }
      }
      console.log(totalshare)
      console.log(moment(new Date()).format("DD/MM/YYYY") )
      setSameDayReport(totalshare)
  },[samedayhis,employ])
  samedayreportt=samedayreport
  //xong

    const handleDateChange = (date) => {
      let reauth=prompt("nhập lại mật khẩu")
      if(reauth==="caobot"){
        alert("enjoy")
        setSelectedDate(date)
        console.log(new Date (new Date(date.setDate(1)).setHours(0,0,0)))
        setDate1(new Date (new Date(date.setDate(1)).setHours(0,0,0)))
        setDate2(new Date(getYear(date),(getMonth(date)+1),0))  
        } else {
          alert("bruhhhh")
          app.auth().signOut()
        }
    }
    console.log(selectedDate, getMonth(selectedDate),getYear(selectedDate))
    console.log(moment(selectedDate).format("DD/MM/YYYY"))
    console.log('01/'+(getMonth(selectedDate)+1)+"/"+getYear(selectedDate))
    //chọn tháng xong sẽ useEffect để lấy data fb theo thời gian tương ứng
    useEffect(()=>{
        setDate2(new Date(date2.setHours(23,59,59)))
        let user = firebase.auth().currentUser;
    //lấy data fb receipt
    db.collection("receipt")
      .where("createdAt", ">=", date1).where("createdAt", "<=", date2)
      .get()
      .then((onSnapshot) => {
        onSnapshot.forEach((doc) => {
          if (user.email === "phat.phamqt@gmail.com" || user.email === "cn2@gmail.com") {
            if (doc.data().branch === 2) {

              idhis.push(doc.id)
              receipthis.push(doc.data())
              nhanvien.push(doc.data().share)
              if (doc.data().credit === true) {
                tocre.push(doc.data().total)
              } else {
                torev.push(doc.data().total)
              }
              tosha.push(doc.data().share)
            }
          } else if (user.email === "cn1@gmail.com") {
            if (doc.data().branch === 1) {
              idhis.push(doc.id)
              receipthis.push(doc.data())
              nhanvien.push(doc.data().share)
              if (doc.data().credit === true) {
                tocre.push(doc.data().total)
              } else {
                torev.push(doc.data().total)
              }
              tosha.push(doc.data().share)
            }
          }
        })
        console.log(nhanvien)
        console.log(receipthis)

        for (let i = 0; i < idhis.length; i++) {
          receipthis[i].id = idhis[i]
        }
        for (let i = 0; i < nhanvien.length; i++) {
          receipthis[i].share = nhanvien[i]
        }
        for (let i = 0; i < receipthis.length; i++) {
          receipthis[i].newsort = (receipthis[i].createdAt)
        }
        function compare(a, b) {
          const bandA = a.newsort;
          const bandB = b.newsort;

          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
        }
        receipthis.sort(compare)
        setHis(receipthis)
        setSameDayHis(receipthis)
        //tính tổng thu và tổng chiết khấu trong khoảng thời gian đã chọn
        setTotalCre(tocre.reduce(reducer))
        setTotalRev(torev.reduce(reducer))
        setTotalShare(tosha.reduce(reducer))

      })
      //lấy data fb receipt xong
      
      //lấy data fb expense
    let exp = []
    let totalexp = [0]
    db.collection("expense")
      .where("createdAt", ">=", date1).where("createdAt", "<=", date2)
      .get()
      .then((onSnapshot) => {
        onSnapshot.forEach((doc) => {
          exx.push(doc.data())
          if (user.email === "phat.phamqt@gmail.com" || user.email === "cn2@gmail.com") {
            if (doc.data().branch === 2) {
              exp.push(doc.data())
              totalexp.push(Number(doc.data().price))
            }
          } else if (user.email === "cn1@gmail.com") {
            if (doc.data().branch === 1) {
              exp.push(doc.data())
              totalexp.push(Number(doc.data().price))
            }
          }
        })
        setEx(exx)
        function compareexp(a, b) {
          const bandA = a.createdAt;
          const bandB = b.createdAt;

          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
        }
        console.log(exp, totalexp)
        exp.sort(compareexp)
        setExpense(exp)
        setTotalExp(totalexp.reduce(reducer))
        console.log(exp, totalexp)
      })
      //lấy data fb expense xong
    },[selectedDate])
    console.log(date1,date2)

    //công thức cộng
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    //
    useEffect(()=>{
        let fbemp=[]
        db.collection("staff")
        .get()
        .then((onSnapshot)=>{
            onSnapshot.forEach((doc)=>{
                fbemp.push(doc.data())
            })
            function compare(a, b) {
                const bandA = a.num;
                const bandB = b.num;
              
                let comparison = 0;
                if (bandA > bandB) {
                  comparison = 1;
                } else if (bandA < bandB) {
                  comparison = -1;
                }
                return comparison;
            }
            fbemp.sort(compare)    
            setEmploy(fbemp)
        })
      },[])
    //function bật tắt xem nội dung
    function dropdown(){
        if(show==="none"){
            setShow("block")
        } else {
            setShow("none")
        }
    }
 
    lichsuchi = expense
    tonghoadon = totalRev
    tongthe = totalCre
    tongchi = totalexpense
    tongchietkhau = totalShare
    console.log(date2.getDate()-date1.getDate())
    
    return <div className="phone">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/yyyy"
                    views={["month"]}
                    margin="none"
                    id="date-picker-inline"
                    label="Date picker inline"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        "aria-label": "change date"
                    }}
                />
            </Grid>
        </MuiPickersUtilsProvider>
        <br></br>
        <ComponentToWatch/>
        <br></br>
        <table style={{width:"100%"}}>
        <tr>
        <td colSpan="4">
        <button className="accordion" onClick={dropdown} style={{textAlign:"center",fontSize:"20px",width:"100%"}}>
            <MenuIcon color="primary" style={{fontSize:"40"}}  />
        </button>
        </td>
        </tr>
        </table>
        <div style={{display:show,width:"100%"}}>  
        
        <table id="historytable">
          <tr>
            <th>Ngày</th>
            <th>Loại</th>
            <th>Giá {totalexpense} </th>
            <th>Ghi chú</th>
          </tr>
          {expense.map(expe=>(
            <tr>
              <td>{expe.date} </td>
              <td>{expe.category} </td>
              <td>{expe.price} </td>
              <td>{expe.note} </td> 
            </tr>
          ))}
        </table>
        </div>

      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        weekends={true}
        
      />

    </div>

}


class ComponentToWatch extends React.Component{
  constructor(props){
    super(props)
    this.state={show:"none"}
    this.dropdowns=this.dropdowns.bind(this)

  }
  dropdowns(){ 
    if(this.state.show==="none"){
        this.setState({show:"block"})
    } else {
      this.setState({show:"none"})
    }
  }
    render(){

      let dien=[0]
      let comtrua=[0]
      let nhuyeupham=[0]
      let nhaphang=[0]
      let nha=[0]
      let nuoc=[0]
      let luong=[0]
      let chitietluong=[]
      for(let i=0;i<lichsuchi.length;i++){
        if(lichsuchi[i].category==="Điện"){
          dien.push(Number(lichsuchi[i].price))
        } if(lichsuchi[i].category==="Cơm trưa"){
          comtrua.push(Number(lichsuchi[i].price))
        } if(lichsuchi[i].category==="Nhu yếu phẩm"){
          nhuyeupham.push(Number(lichsuchi[i].price))
        } if(lichsuchi[i].category==="Nhập hàng"){
          nhaphang.push(Number(lichsuchi[i].price))
        } if(lichsuchi[i].category==="Nhà"){
          nha.push(Number(lichsuchi[i].price))
        } if(lichsuchi[i].category==="Nước"){
          nuoc.push(Number(lichsuchi[i].price))
        } if(lichsuchi[i].category==="Lương"){
          luong.push(Number(lichsuchi[i].price))
          chitietluong.push(lichsuchi[i])
        }
      }
      const data = [
        {ten:"Điện",tien:dien.reduce(reducer)},
        {ten:"Cơm trưa",tien:comtrua.reduce(reducer)},
        {ten:"Nhu yếu phẩm",tien:nhuyeupham.reduce(reducer)},
        {ten:"Nhập hàng",tien:nhaphang.reduce(reducer)},
        {ten:"Nhà",tien:nha.reduce(reducer)},
        {ten:"Nước",tien:nuoc.reduce(reducer)},
        {ten:"Lương",tien:luong.reduce(reducer)},
      ];
      console.log(data)
      console.log(chitietluong)
      return(<div>
        <table id="historytable" >
        <tr>
            <th>Tổng Thu</th>
            <th>{tonghoadon+tongthe} </th>
          </tr>
          <tr>
            <th>Tổng Tiền Mặt</th>
            <th>{tonghoadon} </th>
          </tr>
          <tr>
            <th>Tổng Thẻ</th>
            <th>{tongthe} </th>
          </tr>
          <tr>
            <th style={{display:"flex",justifyContent:"space-between"}} >
              <div style={{padding:0,margin:0,alignSelf:"center"}}>Chiết khấu</div>         
              <button className="accordionn" onClick={this.dropdowns} style={{textAlign:"center",fontSize:"20px"}}>
                <MenuIcon color="primary" style={{fontSize:"30"}}  />
              </button> 
            </th>
            <th>{tongchietkhau} </th>
          </tr>
          <tr>
            <td colSpan="2" style={{padding:"0px"}}>
              <div style={{display:this.state.show,width:"100%"}}>
                <div className="one">
                  {samedayreportt.map((report) => (
                    <td key={report.id}>{report.name} {report.sale}</td>
                  ))}
                </div>

              </div>
            </td>
          </tr>
          <tr>
            <th>Tổng chi</th>
            <th>{tongchi} </th>
          </tr>
          <tr>
            <td>Nhà </td>
            <td>{nha.reduce(reducer)} </td>
          </tr>
          <tr>
            <td>Điện </td>
            <td>{dien.reduce(reducer)} </td>
          </tr>
          <tr>
            <td>Nước </td>
            <td>{nuoc.reduce(reducer)} </td>
          </tr>
          <tr>
            <td>Nhập hàng </td>
            <td>{nhaphang.reduce(reducer)} </td>
          </tr>
          <tr>
            <td>Nhu yếu phẩm </td>
            <td>{nhuyeupham.reduce(reducer)} </td>
          </tr>
          <tr>
            <td>Cơm trưa </td>
            <td>{comtrua.reduce(reducer)} </td>
          </tr>
          <tr>
            <td>Lương </td>
            <td>{luong.reduce(reducer)} </td>
          </tr>
            {chitietluong.map(lu=>(
              <tr>
              <td>{lu.note} </td>
              <td>{lu.price} </td>
              </tr>
            ))}
          <tr>
            <th>Còn lại </th>
            <th>{tonghoadon+tongthe -tongchietkhau-tongchi} </th>
          </tr>
          
        </table>
        <Paper>
        <Chart
          data={data}
        >
          <PieSeries
            valueField="tien"
            argumentField="ten"
          />
          <Title
            text="phan tich chi phi"
          />
          <Animation />
        </Chart>
      </Paper>
      </div>
      )
    }
  }