import React, { useState, useEffect,useRef } from 'react'
import "firebase/firestore";
import firebase from 'firebase/app'
import { makeStyles } from '@material-ui/core/styles';
import { menu, employee } from '../Sources/Menu'
import {ScrollToTop} from './Time'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Modal from 'react-modal'
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import {CSVLink} from 'react-csv'


import Button from '@material-ui/core/Button';
import {useReactToPrint} from 'react-to-print'

const headers = [
  { label: 'So HD', key: 'num' },
  { label: 'Ngay', key: 'date' },
  { label: 'Gio', key: 'time' },
  { label: 'Tong Thu', key: 'total' },
  { label: 'Chi tho', key: 'share' },
  { label: 'chiet khau', key: 'remuneration'}
]

const db = firebase.firestore();

var moment = require('moment');
moment().format();

const useStyles = makeStyles((theme) => ({
  root2: {
    marginTop: 10,
    width: '96%',
    maxWidth: 360,
    backgroundColor: theme.palette.action.selected,
    borderRadius: 10,
    paddingLeft: 10,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 50,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField:{
    width:120,
    height:24,
    fontSize:18,
  },
}));
let lichsuchi=[]
let tonghoadon=[]
let tongthe=[]
let tongchi=[]
let tongchietkhau=[]
let innv=[]
const reducer = (accumulator, currentValue) => accumulator + currentValue;
export function History() {  
  //tùy chọn kết quả tìm kiếm
  const [checked,setChecked]=useState(false)
  //
  const [reporter,setReporter] = useState("")
  //
  const [name, setName] = useState('');
  const classes = useStyles();
  const [employ,setEmploy] = useState(employee)
  //
  const componentRef= useRef();

  const handlePrint= useReactToPrint({
      content:() => componentRef.current,
  })
  const componentRefShare= useRef();

  const handlePrintShare= useReactToPrint({
      content:() => componentRefShare.current,
  })
  //mate ui
  //select date
  console.log(new Date().setHours(0,0,0))
  const [startDate1, setStartDate1] = useState(new Date());
  const [date1, setDate1] = useState(new Date (new Date().setHours(0,0,0)))
  const [date2, setDate2] = useState(new Date (new Date().setHours(23,59,0)))
  const [startDate2, setStartDate2] = useState(new Date());
  const [filterdate1, setFilter1]=useState(new Date())
  const [filterdate2, setFilter2]=useState(new Date())
  // lịch sử chi
  const [expense,setExpense] =useState([])

  const[totalexpense,setTotalExp]=useState("")
  //modal
  const [modalIsopen, setModalIsOpen] = useState(false)
  const [requiremodal, setRequireModal] = useState({
    id: 0,
    total: 0,
    share: 0,
    services: [{
      category: "NU",
      chietkhau: [{ name: "Dương", tienchietkhau: 70 }],
      help:[{ name: "Dương", tienchietkhau: 70 }],
      name: "Duỗi",
      num: 4,
      price: 700,
      stt: 0,
    }],
  })
  useEffect(()=>{
    let fbemp=[]
    db.collection("staff")
    .get()
    .then((onSnapshot)=>{
        onSnapshot.forEach((doc)=>{
            fbemp.push(doc.data())
        })
        function compare(a, b) {
            const bandA = a.num;
            const bandB = b.num;
          
            let comparison = 0;
            if (bandA > bandB) {
              comparison = 1;
            } else if (bandA < bandB) {
              comparison = -1;
            }
            return comparison;
        }
        fbemp.sort(compare)    
        setEmploy(fbemp)
    })
  },[])
  //show and hide element select
  let changeservice = []
  let changeprice = []
  const [show, setShow] = useState(true)
  const [hide, setHide] = useState(false)
  function shownhide() {
    setShow(!show)
    setHide(!hide)
  }
  //THAY ĐỔI TÊN DỊCH VỤ
  const handleChangeService = (event) => {
    if (event.target.value.length > 0) {
      // setName(event.target.value)
      changeservice.push(event.target.value)
    }
  };
  const handleService = (service, index) => {
    console.log(index)
    console.log(service)
    let ser = JSON.parse(JSON.stringify(requiremodal))
    if (changeservice[0] !== undefined) {
      console.log(ser.services[index].name)
      ser.services[index].name = changeservice[0]
      //
      let share = [0]
      for (let i = 0; i < ser.services.length; i++) {
        for (let a = 0; a < ser.services[i].chietkhau.length; a++) {
          if (ser.services[i].chietkhau[a] !== undefined) {
            share.push(ser.services[i].chietkhau[a].tienchietkhau)
            console.log(share)
          }
        }
      }
      //
      let coumt = [0]
      for (let i = 0; i < ser.services.length; i++) {
        coumt.push(ser.services[i].price)
      }
      ser.share = share.reduce(reducer)
      ser.total = coumt.reduce(reducer)
      //
      let serviceRef = db.collection("receipt").doc(requiremodal.id);
      return serviceRef.update({
        date: ser.date,
        id: ser.id,
        time: ser.time,
        services: ser.services,
        total: ser.total,
        share: ser.share
      })
        .then(function () {
          console.log("Document successfully updated!");
          db.collection("receipt").doc(requiremodal.id)
            .onSnapshot(function (doc) {
              setRequireModal(ser)
              console.log("Current data: ", doc.data());
            });

        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    } else {
      console.log(ser.services[index])
      let serviceRef = db.collection("receipt").doc(requiremodal.id);
      return serviceRef.update({
        date: ser.date,
        id: ser.id,
        time: ser.time,
        services: ser.services,
        total: ser.total,
        share: ser.share
      })
    }

  }
  //THAY ĐỔI GIÁ DỊCH VỤ
  const handleChangePrice = (event) => {
    console.log(event.target.value)
    changeprice.push(event.target.value)
    setName(event.target.value)
  };
  const handlePrice = (service, index) => {
    console.log(index)
    console.log(service)
    let ser = { ...requiremodal }
    if (name !== undefined && isNaN(name) === false && name.length > 0) {
      console.log(ser.services[index].name)
      ser.services[index].price = Number(name)
      //

      let share = [0]
      for (let i = 0; i < ser.services.length; i++) {
        let plusshare = ser.services[i].chietkhau
        let plushelp = ser.services[i].help
        for (let b = 0; b < plusshare.length; b++) {
          share.push(plusshare[b].tienchietkhau)
          console.log(share)
        }
        for (let c = 0; c < plushelp.length; c++) {
          share.push(plushelp[c].tienchietkhau)
          console.log(share)
        }

      }

      //
      let coumt = [0]
      for (let i = 0; i < ser.services.length; i++) {
        coumt.push(ser.services[i].price)
      }
      ser.share = share.reduce(reducer)
      ser.total = coumt.reduce(reducer)
      //
      let serviceRef = db.collection("receipt").doc(requiremodal.id);
      return serviceRef.update({
        date: ser.date,
        id: ser.id,
        time: ser.time,
        services: ser.services,
        total: ser.total,
        share: ser.share
      })
        .then(function () {
          console.log("Document successfully updated!");
          db.collection("receipt").doc(requiremodal.id)
            .onSnapshot(function (doc) {
              setRequireModal(ser)
              console.log("Current data: ", doc.data());
            });

        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    } else {
      console.log(ser.services[index])
      let serviceRef = db.collection("receipt").doc(requiremodal.id);
      return serviceRef.update({
        date: ser.date,
        id: ser.id,
        time: ser.time,
        services: ser.services,
        total: ser.total,
        share: ser.share
      })
    }

  }
  //
  console.log(requiremodal)
  const [his, setHis] = useState([])
  const [samedayhis,setSameDayHis] =useState([])
  const [samedayreport,setSameDayReport]=useState([])
  const [totalRev,setTotalRev]=useState([])
  const [totalCre,setTotalCre]=useState([])
  const [totalShare,setTotalShare]=useState([])
  let torev=[0];
  let tocre=[0]
  let tosha=[0];
  let receipthis = []
  let idhis = []
  let nhanvien = []
 
  let exx=[] 
  const[ex,setEx]=useState("")

  const[inbaocaonv,SetBaoCao]=useState("")
  useEffect(()=>{
    let totalshare=[]
    console.log(samedayhis)
    for(let i=0;i<employ.length;i++){
        totalshare.push({
            name:employ[i].name,
            sale:0
        })
    }
    console.log(totalshare)
    for(let i=0;i<samedayhis.length;i++){
        for(let j=0;j<samedayhis[i].services.length;j++){
            for(let k=0;k<samedayhis[i].services[j].chietkhau.length;k++){
                for(let l=0;l<totalshare.length;l++ ){
                    if(totalshare[l].name===samedayhis[i].services[j].chietkhau[k].name){
                        totalshare[l].sale+=samedayhis[i].services[j].chietkhau[k].tienchietkhau
                    }
                }
            }
            for(let k=0;k<samedayhis[i].services[j].help.length;k++){
                for(let l=0;l<totalshare.length;l++ ){
                    if(totalshare[l].name===samedayhis[i].services[j].help[k].name){
                        totalshare[l].sale+=samedayhis[i].services[j].help[k].tienchietkhau
                    }
                }
            }
        }
    }
    console.log(totalshare)
    console.log(moment(new Date()).format("DD/MM/YYYY") )
    setSameDayReport(totalshare)
},[samedayhis,employ])
  useEffect(()=>{
    let baocaonv=[]
    console.log(his)
    console.log(reporter)
    console.log(filterdate1,filterdate2)
    for(let i=0;i<his.length;i++){
      for(let c=0;c<his[i].services.length;c++){
          for(let d=0;d<his[i].services[c].chietkhau.length;d++){
            if(his[i].services[c].chietkhau[d].name===reporter){
              baocaonv.push({ngay:his[i].date,sohoadon:his[i].num,dichvu:his[i].services[c].name,gia:his[i].services[c].price,chietkhau:his[i].services[c].chietkhau[d].tienchietkhau})  
            }
          }
          for(let d=0;d<his[i].services[c].help.length;d++){
            if(his[i].services[c].help[d].name===reporter){
              baocaonv.push({ngay:his[i].date,sohoadon:his[i].num,dichvu:his[i].services[c].name,gia:his[i].services[c].price,chietkhau:his[i].services[c].help[d].tienchietkhau})  
            }
          }
      }
    }
    console.log(baocaonv)
    SetBaoCao(baocaonv)
    innv=baocaonv
  },[his,reporter,filterdate1,filterdate2])
  //function getdata lấy data của collection expense và receipt 
  function getdata() {

    let user = firebase.auth().currentUser;
    //lấy data fb receipt
    if(checked===false){
      db.collection("receipt")
      .where("createdAt", ">=", date1).where("createdAt", "<=", date2)
      .get()
      .then((onSnapshot) => {
        onSnapshot.forEach((doc) => {
          if (user.email === "phat.phamqt@gmail.com" || user.email === "cn2@gmail.com") {
            if (doc.data().branch === 2) {
              idhis.push(doc.id)
              receipthis.push(doc.data())
              nhanvien.push(doc.data().share)
              if (doc.data().credit === true) {
                tocre.push(doc.data().total)
              } else {
                torev.push(doc.data().total)
              }
              tosha.push(doc.data().share)
            }
          } else if (user.email === "cn1@gmail.com") {
            if (doc.data().branch === 1) {
              idhis.push(doc.id)
              receipthis.push(doc.data())
              nhanvien.push(doc.data().share)
              if (doc.data().credit === true) {
                tocre.push(doc.data().total)
              } else {
                torev.push(doc.data().total)
              }
              tosha.push(doc.data().share)
            }
          }
        })
        console.log(nhanvien)
        console.log(receipthis)

        for (let i = 0; i < idhis.length; i++) {
          receipthis[i].id = idhis[i]
        }
        for (let i = 0; i < nhanvien.length; i++) {
          receipthis[i].share = nhanvien[i]
        }
        for (let i = 0; i < receipthis.length; i++) {
          receipthis[i].newsort = (receipthis[i].createdAt)
        }
        function compare(a, b) {
          const bandA = a.newsort;
          const bandB = b.newsort;

          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
        }
        receipthis.sort(compare)
        setHis(receipthis)
        setSameDayHis(receipthis)
        //tính tổng thu và tổng chiết khấu trong khoảng thời gian đã chọn
        setTotalCre(tocre.reduce(reducer))
        setTotalRev(torev.reduce(reducer))
        setTotalShare(tosha.reduce(reducer))

      })
    } else if(checked===true){
      console.log(checked)
      db.collection("receipt")
      .where("createdAt", ">=", date1).where("createdAt", "<=", date2)
      .get()
      .then((onSnapshot) => {
        onSnapshot.forEach((doc) => {
          if (user.email === "phat.phamqt@gmail.com" || user.email === "cn2@gmail.com") {
            if (doc.data().branch === 2 && doc.data().credit=== true) {
              idhis.push(doc.id)
              receipthis.push(doc.data())
              nhanvien.push(doc.data().share)
              if (doc.data().credit === true) {
                tocre.push(doc.data().total)
              } else {
                torev.push(doc.data().total)
              }
              tosha.push(doc.data().share)
            }
          } else if (user.email === "cn1@gmail.com") {
            if (doc.data().branch === 1 && doc.data().credit===true) {
              idhis.push(doc.id)
              receipthis.push(doc.data())
              nhanvien.push(doc.data().share)
              if (doc.data().credit === true) {
                tocre.push(doc.data().total)
              } else {
                torev.push(doc.data().total)
              }
              tosha.push(doc.data().share)
            }
          }
        })
        console.log(nhanvien)
        console.log(receipthis)

        for (let i = 0; i < idhis.length; i++) {
          receipthis[i].id = idhis[i]
        }
        for (let i = 0; i < nhanvien.length; i++) {
          receipthis[i].share = nhanvien[i]
        }
        for (let i = 0; i < receipthis.length; i++) {
          receipthis[i].newsort = (receipthis[i].createdAt)
        }
        function compare(a, b) {
          const bandA = a.newsort;
          const bandB = b.newsort;

          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
        }
        receipthis.sort(compare)
        setHis(receipthis)
        setSameDayHis(receipthis)
        //tính tổng thu và tổng chiết khấu trong khoảng thời gian đã chọn
        setTotalCre(tocre.reduce(reducer))
        setTotalRev(torev.reduce(reducer))
        setTotalShare(tosha.reduce(reducer))

      })
    }
    
  }

  //CÀI ĐẶT XEM HÓA ĐƠN THEO NGÀY
  function handleChange1(value) {
    value.setHours(0,0,0)
    const valueOfInput = moment(value).format("DD/MM/YYYY");
    setStartDate1(value)
    setDate1(value)
    setFilter1(valueOfInput)
  }
  function handleChange2(value) {
    value.setHours(0,0,0)
    const valueOfInput = moment(value).format("DD/MM/YYYY");
    setStartDate2(value)
    setDate2(value)
    setFilter2(valueOfInput)
  }
  //CÀI MODAL CỦA HÓA ĐƠN MUỐN XEM
  let employy = []
  let helper = []
  function handleDBclick(re) {
    console.log(re)
    setRequireModal(his[re])
  }
  function moreDoer(index) {
    console.log(index)
    let addDoer = JSON.parse(JSON.stringify(requiremodal));
    addDoer[index].chietkhau.push({
      name: employ[0].name,
      tienchietkhau: (addDoer[index].price * employ[0].percent / 100)
    })
    setRequireModal(addDoer)
  };
  const handleChangeDoer = (event, index, dex) => {
    console.log(event.target.value)
    console.log(index,dex)
    console.log(requiremodal)
    for (let i = 0; i < employ.length; i++) {
      if (event.target.value === employ[i].name) {
        console.log(employ[i])
        employy.push(employ[i])
      }
    }
    let newhoadon = JSON.parse(JSON.stringify(requiremodal));
    if (employy.length > 0) {
      let a = newhoadon.services[index].chietkhau
      a[dex] = {
        name: employy[0].name,
        tienchietkhau: (newhoadon.services[index].price * employy[0].percent / 100)
      }
    } else {
      newhoadon.services[index].chietkhau = {
        name: employ[0].name,
        tienchietkhau: (newhoadon[index].price * employ[0].percent / 100)
      }
    }
    setRequireModal(newhoadon)
  };
  function moreHelper(index) {
    console.log(index)
    let addDoer = JSON.parse(JSON.stringify(requiremodal));
    addDoer[index].help.push({
      name: employ[0].name,
      tienchietkhau: (addDoer[index].price * employ[0].percent / 100)
    })
    setRequireModal(addDoer)
  };
  const handleChangeHelper = (event, index, dex) => {
    console.log(event.target.value)
    console.log(index)
    for (let i = 0; i < employ.length; i++) {
      if (event.target.value === employ[i].name) {
        console.log(employ[i])
        helper.push(employ[i])
      }
    }
    let newhoadon = [...requiremodal]
    if (helper.length > 0) {
      if (newhoadon[index].type !== "che") {
        if (helper[0].type === "fix") {
          let a = newhoadon[index].help
          a[dex] = {
            name: helper[0].name,
            tienchietkhau: (newhoadon[index].fix / a.length)
          }
        } else if (helper[0].type === "partner") {
          let a = newhoadon[index].help
          a[dex] = {
            name: helper[0].name,
            tienchietkhau: (newhoadon[index].fix / a.length)
          }
        }
      } else {
        if (helper[0].type === "fix") {
          let a = newhoadon[index].help
          a[dex] = {
            name: helper[0].name,
            tienchietkhau: (newhoadon[index].fix / a.length)
          }
        } else if (helper[0].type === "partner") {
          let a = newhoadon[index].help
          a[dex] = {
            name: helper[0].name,
            tienchietkhau: (newhoadon[index].price * 0.2 / a.length)
          }
        }
      }


    } else {
      newhoadon[index].help = {
        name: employ[0].name,
        tienchietkhau: (newhoadon[index].price * employ[0].percent / 100)
      }
    }
    setRequireModal(newhoadon)
  };
  //XÓA LỊCH SỬ
  function deletehistory(index){
    db.collection("receipt").doc(his[index].id).delete().then(function() {
      console.log("Document successfully deleted!");
  }).catch(function(error) {
      console.error("Error removing document: ", error);
  });
  }

  function handleReporter(event){
    console.log(event.target.value)
    setReporter(event.target.value)
  }
  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log(event.target.checked)
  };
  return <div id="history">
    <div className="datefilter">

      <DatePicker  className={classes.textField} selected={startDate1} onChange={(value) => handleChange1(value)} />
      &nbsp; - &nbsp;
      <DatePicker className={classes.textField} selected={startDate2} onChange={(value) => handleChange2(value)} />
      &nbsp; &nbsp;
      <IconButton onClick={getdata}><SearchIcon /></IconButton>

      <FormControlLabel
                        control={<Checkbox checked={checked} onChange={handleChange} name="checkedB" color="primary" />}
                        label="Trả thẻ"
                        className={classes.margin1}
                    />
    </div>
    <div >
    <br></br>

      {/* <Button variant="contained" size="small" color="primary" className={classes.margin} onClick={handlePrint} >In Báo Cáo</Button> */}
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={reporter}
        onChange={(e) => { handleReporter(e) }}
      >
        {employ.map(em => (
          <MenuItem value={em.name}>{em.name}</MenuItem>
        ))}
      </Select>
      &nbsp;&nbsp;
      <Button variant="contained" size="small" color="primary" className={classes.margin} onClick={handlePrintShare} >In Nhân Viên</Button>    
      
      <CSVLink data={his} headers={headers} separator={";"}>xuat excel</CSVLink>
      <br></br>
      <br></br>
      <div style={{display:'none'}}>
      {/* <ComponentToPrint ref={componentRef}/> */}
      <ComponentToPrintShare ref={componentRefShare}/> 
      {/* <ComponentToWatch/> */}
      </div>
      
      <ScrollToTop/>    
      {/* PHẦN NÀY CỦA TABLE */}
      <table id="historytable">
      <tr>
        <td colSpan="7">
            <div>
                <div className="one">
                {samedayreport.map((report) => (
                  <td key={report.id}>{report.name} {report.sale}</td>
            ))}
                </div>

            </div>
        </td>
        </tr>    
        <tr className="historyrow">
          <th>Số HĐ</th>
          <th>Ngày</th>
          <th className="short">Giờ</th>
          <th>Tổng thu {totalRev+totalCre}</th>
          <th className="short">Chia thợ</th>
          <th>Công thợ {totalShare}</th>
          <th>Chi tiết</th>
        </tr>

        {his.map((receipt, index) => (          
          <tr key={index} >
            <td>{receipt.num} </td>
            <td>{receipt.date} </td>
            <td className="short">{receipt.time} </td>
            <td>{receipt.total} <input type="checkbox" checked={receipt.credit}  disabled /> </td>
            <td className="short">{receipt.share} </td>
            <td>
            {receipt.services.map((service,index)=>(
              <div>
                <td className="detail">
                {service.name}  
                {service.chietkhau.map((doer,dex)=>(
                  <div>
                    : {doer.name} {doer.tienchietkhau}
                  </div>
                ))}
                {service.help.map((helper,dex)=>(
                  <div>
                    , {helper.name} {helper.tienchietkhau}
                  </div>
                ))}
                </td>
              </div>
            ))}
            </td>
            <td>
              <IconButton size="small" onClick={() => { handleDBclick(index); setModalIsOpen(true) }}><MoreHorizRoundedIcon /></IconButton>
              <IconButton size="small" onClick={() => { deletehistory(index)}}><DeleteForeverRoundedIcon /></IconButton>    
            </td>
          </tr>
        ))}
      </table>

      {/* bảng chi phí */}

      {/* PHẦN NÀY CỦA MODAL */}
      <Modal isOpen={modalIsopen} onRequestClose={() => {
        setModalIsOpen(false);
        getdata()
      }} id="hoadon">
        <div className={classes.root2}>
          <button onClick={shownhide} >edit</button>
          <ListItemText><p>Mã hóa đơn: {requiremodal.id} </p></ListItemText>
          <ListItemText><p>Tổng tiền thanh toán: {requiremodal.total} </p></ListItemText>
          <ListItemText><p>Tổng tiền chiết khấu: {requiremodal.share} </p></ListItemText>

          {requiremodal.services.map((service, index) => (
            <List>
              <div className={classes.root2} >
                {show ?
                  <div>
                    <p>Dịch vụ: {service.name} </p>
                    <p>Chi phí: {service.price} </p>
                    <p>Thợ chính:</p>
                          {service.chietkhau.map((doer, dex) => (
  <div>
  <FormControl className={classes.formControl}>

      <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={doer.name}
          onChange={(e) => { handleChangeDoer(e,index,dex) }}
      >
          {employ.map(em => (
              <MenuItem value={em.name}>{em.name}</MenuItem>
          ))}
      </Select>
      {doer.tienchietkhau}
  </FormControl>

<IconButton size="small" variant="contained" onClick={()=>{moreDoer(index) }} ><AddCircleOutlineRoundedIcon color="primary" fontSize="small"/></IconButton>
</div>
))}

<p>Thợ phụ:</p>    
{service.help.map((doer,dex)=>(
<div>
  <FormControl className={classes.formControl}>
      <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={doer.name}
          onChange={(e) => { handleChangeHelper(e, index,dex) }}
      >
          {employ.map(em => (
              <MenuItem value={em.name}>{em.name}</MenuItem>
          ))}
      </Select>
      {doer.tienchietkhau}
  </FormControl>

<IconButton size="small" variant="contained" onClick={()=>{moreHelper(index) }} ><AddCircleOutlineRoundedIcon color="primary" fontSize="small"/></IconButton>
</div>))}

                    </div>
                  : null}
                {hide ?
                      <div className="button">
                        <FormControl className={classes.formControl}>
                          <p>Dịch vụ hiện tại: {service.name} </p>
                          <p>Chọn mới</p>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={name.name}
                            onChange={handleChangeService}
                            onClick={() => { handleService(service, index) }}
                          >
                            {menu.map(em => (
                              <MenuItem value={em.name}>{em.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <p>Giá cũ: {service.price} </p>
                        <p>Giá mới:</p>
                        <input type="text" onChange={handleChangePrice} onKeyDown={(e) => { if (e.key === "Enter") { handlePrice(service, index) } }} id="inputprice" />
                      </div>
                      : null}
                  </div>
              </List>
            ))}
          
        </div>
      </Modal>
    </div>
    </div>
}

//  class ComponentToPrint extends React.Component{
//   render(){
//     let dien=[0]
//     let comtrua=[0]
//     let nhuyeupham=[0]
//     let nhaphang=[0]
//     let nha=[0]
//     let nuoc=[0]
//     let luong=[]
//     for(let i=0;i<lichsuchi.length;i++){
//       if(lichsuchi[i].category==="Điện"){
//         dien.push(Number(lichsuchi[i].price))
//       } if(lichsuchi[i].category==="Cơm trưa"){
//         comtrua.push(Number(lichsuchi[i].price))
//       } if(lichsuchi[i].category==="Nhu yếu phẩm"){
//         nhuyeupham.push(Number(lichsuchi[i].price))
//       } if(lichsuchi[i].category==="Nhập hàng"){
//         nhaphang.push(Number(lichsuchi[i].price))
//       } if(lichsuchi[i].category==="Nhà"){
//         nha.push(Number(lichsuchi[i].price))
//       } if(lichsuchi[i].category==="Nước"){
//         nuoc.push(Number(lichsuchi[i].price))
//       } if(lichsuchi[i].category==="Lương"){
//         luong.push(lichsuchi[i])
//       }
//     }
//     return(<div style={{ width: "48mm", height: "fit-content", margin: "5px", padding: "0px" }}>
//       <table id="tablereceipt" style={{width:"95%"}}>
//         <tr>
//           <th>Tổng Thu</th>
//           <th>{tonghoadon} </th>
//         </tr>
//         <tr>
//           <th>Chiết khấu </th>
//           <th>{tongchietkhau} </th>
//         </tr>
//         <tr>
//           <th>Tổng chi</th>
//           <th>{tongchi} </th>
//         </tr>
//         <tr>
//           <td>Nhà </td>
//           <td>{nha.reduce(reducer)} </td>
//         </tr>
//         <tr>
//           <td>Điện </td>
//           <td>{dien.reduce(reducer)} </td>
//         </tr>
//         <tr>
//           <td>Nước </td>
//           <td>{nuoc.reduce(reducer)} </td>
//         </tr>
//         <tr>
//           <td>Nhập hàng </td>
//           <td>{nhaphang.reduce(reducer)} </td>
//         </tr>
//         <tr>
//           <td>Nhu yếu phẩm </td>
//           <td>{nhuyeupham.reduce(reducer)} </td>
//         </tr>
//         <tr>
//           <td>Cơm trưa </td>
//           <td>{comtrua.reduce(reducer)} </td>
//         </tr>
//         <tr>
//           <td>Lương </td>
//           <td></td>
//         </tr>
//           {luong.map(lu=>(
//             <tr>
//             <td>{lu.note} </td>
//             <td>{lu.price} </td>
//             </tr>
//           ))}
//         <tr>
//           <th>Còn lại </th>
//           <th>{tonghoadon+tongthe-tongchietkhau-tongchi} </th>
//         </tr>
        
//       </table>
//     </div>
//     )
//   }
// }

class ComponentToPrintShare extends React.Component{
  render(){
    return (
      <div style={{ width: "44mm", height: "fit-content", margin: "0px", padding: "0px" }}>
        <h2 style={{ textAlign: "center" }}>DŨ SALON</h2>
        
          <table id="tablereceipt" style={{width:"95%",fontSize:"12px"}}>
            <tr>
              <th>Ngày</th>
              <th>DV</th>
              <th>Giá </th>
              <th>CK</th>
            </tr>
            {innv.map(nhanvien=>(
              <tr>
                <td>{nhanvien.ngay}</td>
                <td>{nhanvien.dichvu}</td>
                <td>{nhanvien.gia}</td>
                <td>{nhanvien.chietkhau}</td>
              </tr>
            ))}
          </table>
        
      </div>
    )
  }
}







