import React, { useState, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid';
import "firebase/firestore";
import firebase from 'firebase/app'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { menu } from '../Sources/Menu'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {employee} from '../Sources/Menu'


import Paper from '@material-ui/core/Paper';
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';

import { Animation } from '@devexpress/dx-react-chart';


const db = firebase.firestore();

var moment = require('moment');
moment().format();

const useStyles = makeStyles((theme) => ({
  root2: {
    marginTop: 5,

    backgroundColor: theme.palette.action.selected,
    borderRadius: 10,
    paddingLeft: 5,
  },
  root: {
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  rootselectmonth: {
    minWidth: 200,
  },
}));

export function Report() {
  //
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const classes = useStyles();
  //mate ui
  //select date
  const [startDate1, setStartDate1] = useState(new Date());
  const [date1, setDate1] = useState([])
  const [date2, setDate2] = useState([])
  const [startDate2, setStartDate2] = useState(new Date());
  //
  const [chart, setChart] = useState([
    { date: '1950', sale: 2.525 },
    { date: '1960', sale: 3.018 },
    { date: '1970', sale: 3.682 },
    { date: '1980', sale: 4.440 },
    { date: '1990', sale: 5.310 },
    { date: '2000', sale: 6.127 },
    { date: '2010', sale: 6.930 },
  ])
  let chart1=[]
  let chart2=[]

  function getdata() {
    db.collection("receipt")
      .get()
      .then((onSnapshot) => {
        onSnapshot.forEach((doc) => {
          chart1.push(doc.data())
        })
        console.log(chart1.sort())
        for(let i=0;i<chart1.length;i++){
            chart2.push({date:chart1[i].date,sale:chart1[i].total})
        }
        setChart(chart2)
      })
  }
  //CÀI ĐẶT XEM HÓA ĐƠN THEO NGÀY
  function handleChange1(value) {
    const valueOfInput = moment(value).format("DD/MM/YYYY");
    let ad = valueOfInput.split("/")
    console.log(valueOfInput)
    setStartDate1(value)
    setDate1(ad)
  }
  function handleChange2(value) {
    const valueOfInput = moment(value).format("DD/MM/YYYY");
    let ad = valueOfInput.split("/")
    console.log(valueOfInput)
    setStartDate2(value)
    setDate2(ad)
  }
  console.log(chart)
  return <div id="history">
    <div id="monthnyearfilter">
    <Button color="primary" onClick={getdata}>Xem chơi</Button>
    </div>
    <div id="barchart" >
    <Paper >
        <Chart 
          data={chart}
          
        >   
          <ArgumentAxis />
          <ValueAxis max={7} />

          <BarSeries
            valueField="sale"
            argumentField="date"
            color="#ffd700"
            barWidth="1"
          />
          <Title text="Doanh Thu" />
          <Animation />
        </Chart>
    </Paper>
</div>

  </div>
}










