// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase/app"
import "firebase/auth"


export const firebaseConfig = {
    apiKey: "AIzaSyBl4fZ0ttV1fdCF9t_fVQw8iZYH8_DAZBw",
    authDomain: "ci-project-f9b57.firebaseapp.com",
    databaseURL: "https://ci-project-f9b57.firebaseio.com",
    projectId: "ci-project-f9b57",
    storageBucket: "ci-project-f9b57.appspot.com",
    messagingSenderId: "476316861620",
    appId: "1:476316861620:web:9df4fe979c6c744438c3e1",
    measurementId: "G-RGRX0YBGN4"
  };

 const app=firebase.initializeApp(firebaseConfig)

 
  export default app;  