import React from 'react'

import {Link} from 'react-router-dom'

export function Nav (){
    return<div className="head" style={{width:'100%'}}>
        
        <div className="col-3 item1">
            <a style={{marginLeft:"5px"}} href="https://dusalon.xyz/"><img className="col-3 item1" alt="logo.png" src={process.env.PUBLIC_URL + "/images/logo.png"} style={{width:"auto",maxWidth:"130px",height:"auto",maxHeight:"130px"}} /></a>            
        </div>
        <div className="col-9 nav item2">
            <Link to="/" style={{textDecoration:"none"}}>Tính tiền</Link>
            <Link to="/his" style={{textDecoration:"none"}}>Lịch sử</Link>
            <div className="short"><Link to="/menucontrol" style={{textDecoration:"none"}}>Bảng giá</Link> </div>
            <div className="short"><Link to="/staffcontrol" style={{textDecoration:"none"}}>Nhân viên</Link></div>
            <Link to="/expenses" style={{textDecoration:"none"}}>Chi phí</Link>
            {/* <h4 style={{color:"#8a5337"}}><Link to="/phone" style={{textDecoration:"none"}}>Báo cáo</Link></h4> */}
        </div>
        
    </div>
}