import React, {useEffect,useState} from 'react'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';

// import teal from '@material-ui/core/colors/teal';

// import { createMuiTheme } from '@material-ui/core/styles';
// import { ThemeProvider } from '@material-ui/styles';
// const theme = createMuiTheme({
//     palette: {
//       primary: {
//         main: teal[500],
//       },
//       secondary: {
//         main: teal[700],
//       },
//     },
//   });
  

export function Time(){
    let date = new Date().toLocaleDateString()
    let time = new Date().toLocaleTimeString()

    const [Ctime,setCtime] = useState(time)

    const updateTime =() =>{
        time= new Date().toLocaleTimeString()
        setCtime(time)
    }
    setInterval(updateTime, 1000);
    return <h4 style={{textAlign:"right",paddingRight:"20px", color:"black",margin:"0", marginTop:"21.280px" }}>{Ctime} {date} </h4>
}

export function TimeReceipt(){
    let date = new Date().toLocaleDateString()
    let time = new Date().toLocaleTimeString()

    const [Ctime,setCtime] = useState(time)

    const updateTime =() =>{
        time= new Date().toLocaleTimeString()
        setCtime(time)
    }
    setInterval(updateTime, 1000);
    return <div style={{fontSize:"12px"}}>Ngày: {Ctime} {date} </div>
}

export function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div className="scroll-to-top">
        {/* <ThemeProvider theme={theme}> */}
      {isVisible && 
        <IconButton size="medium" onClick={scrollToTop} ><ExpandLessIcon fontSize="large" color="primary"/> </IconButton>
        }
        {/* </ThemeProvider> */}
    </div>
  );
}