import React,{useState} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import IconButton from '@material-ui/core/IconButton';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import MenuIcon from '@material-ui/icons/Menu';
import "firebase/firestore";
import firebase from 'firebase/app'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@material-ui/icons/CreditCard';
// import { Hidden } from '@material-ui/core';


const db = firebase.firestore();

export function HistoryTable(samedayreport){
    const [showServiceCost, setShowServiceCost] = useState("block")

    const [selectedId,setSelectedId] = useState('')
    const [show, setShow] = useState(false);
    const [pass, setPass] = useState('')

  const handleClose = () => setShow(false);
  const handleShow = (index) => {
    setShow(true)
    setSelectedId(index)
  }
  const handleChangePass = (e) => {

    setPass(e.target.value)
  }
  const handleCheckPass = () => {
    handleClose()
    if(pass === "khumco"){
        db.collection("receipt").doc(samedayreport.samedayhis[selectedId].id).delete()
            .then(function() {
            console.log("Document successfully deleted!");
            alert("Đã xóa!")
            })
            .catch(function(error) {
            console.error("Error removing document: ", error);
            });
    } else {
        alert("Cần nhập đúng mật khẩu để xóa")
    }
  }

    function deletehistory(index){
        console.log(samedayreport.samedayhis[index])
        let confirm= window.prompt("xin lai mat khau")
        if(confirm==="khumco"){
            db.collection("receipt").doc(samedayreport.samedayhis[index].id).delete()
            .then(function() {
            console.log("Document successfully deleted!");
            alert("Đã xóa!")
            })
            .catch(function(error) {
            console.error("Error removing document: ", error);
            });
        } else {
            alert("Tui biết hết!")
        }
        
    }

    function dropdown(){
        if(showServiceCost ==="none"){
            setShowServiceCost("block")
        } else {
            setShowServiceCost("none")
        }
    };


    return <div> <table id="historytable">
        <tbody>
    <tr>
        <td colSpan="7">
        <button className="accordion" onClick={dropdown} style={{textAlign:"center",fontSize:"20px"}}>
            <MenuIcon color="primary" style={{fontSize:"40"}}  />
        </button>
        </td>
    </tr>
    <tr >     
        <td colSpan='7' >
            <table  style={{display:showServiceCost}} >
                <tbody style={{width:'100%'}}>
                <tr className="three"> 

                {samedayreport.samedayreport.map((report) => (
                  <td className='one' key={report.id}>{report.name} {report.sale}</td>
            ))}

                </tr>
                </tbody> 
            </table> 
        </td>

    </tr>
    <tr className="historyrow">
        <th>Số HĐ</th>
        <th className="short">Ngày</th>
        <th>Giờ</th>
        <th>Tổng thu: {samedayreport.totalRev}</th>
        <th className="short">Chia thợ: {samedayreport.totalShare} </th>
        <th></th>
        <th>Chi tiết</th>
    </tr>
    {samedayreport.samedayhis.map((receipt, index) => (
        <tr key={receipt.id} className="historyrow">
            <td>{receipt.num}</td>
            <td className="short">{receipt.date} </td>
            <td>{receipt.time} </td>
            <td>{receipt.total} <input type="checkbox" checked={receipt.credit}  disabled /> 
                {receipt.credit
                ?(<>
                {receipt.cash>0
                ?(<><br></br><AttachMoneyIcon color='primary'/> {receipt.cash}</>)
                :(<></>)
                }                
                <br></br>
                <CreditCardIcon color='primary'/> {receipt.card}
                </>)        
                :(<></>)
                }

            </td>
            <td className="short">{receipt.share} 
                <br></br>
                {receipt.remuneration
                    ? (<div>{receipt.remuneration.map((shareDtail) =>
                        <div>
                            {shareDtail.name}
                            {shareDtail.tienchietkhau}
                        </div>
                    )}</div>)
                    : (<></>)
                }
            </td>
            <td>
                {receipt.services.map((service,dex) => (
                        <div key={service.toString()+dex} className="detail er" style={{border:"1px solid gray",padding:"5px",margin:"5px 0px",borderCollapse:"collapse",overflow:"hidden",borderRadius:"10px"}}>
                            <div className="detail1">
                            {service.name} {service.price}
                            </div>
                            {service.chietkhau.map((doer) => (
                                <div key={doer.toString()+doer.tienchietkhau} className="detail2">
                                    {doer.name} {doer.tienchietkhau}
                                </div>
                            ))}
                            {service.help.map((helper) => (
                                <div>
                                    {helper.name} {helper.tienchietkhau}
                                </div>
                            ))}
                        </div>
                ))}
            </td>
            <td>
                <IconButton size="small"><MoreHorizRoundedIcon /></IconButton>
                {/* <IconButton size="small" onClick={() => { deletehistory(index) }}><DeleteForeverRoundedIcon /></IconButton> */}
                <IconButton variant="primary" onClick={() => {handleShow(index)}} ><DeleteForeverRoundedIcon/></IconButton>                
            </td>

        </tr>
    ))}
    </tbody>

</table>
<ModalDelete 
show={show} 
handleClose={()=>{handleClose()}} 
handleChangePass={(e)=>{handleChangePass(e)}}
handleCheckPass={() => {handleCheckPass()}} 
></ModalDelete>
</div>
}


export function ModalDelete({
    show,
    handleClose,
    handleChangePass,
    handleCheckPass
 }){
    // const handleChangePass = (e) => {
    //     console.log(e.target.value)
    //   }
    console.log(show)
    return <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Nhập lại mật khẩu để xóa</Modal.Title>
    </Modal.Header>
    <Modal.Body>

        <input type='password' onChange={(e)=>{handleChangePass(e)}} ></input>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" onClick={handleCheckPass}>
        Save Changes
      </Button>
    </Modal.Footer>
  </Modal>
}
