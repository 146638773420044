import React, { useEffect } from 'react'
import { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import "firebase/firestore";
import firebase from 'firebase/app'
import { firebaseConfig } from '../Config/firebase.config'
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';

import CancelIcon from '@material-ui/icons/Cancel';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Modal from 'react-modal';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
 
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '400px',
  }
};
 

// try {
//     firebase.initializeApp(firebaseConfig);
// } catch (e) {
//     console.log(e.message)
// }

const db = firebase.firestore();

const useStyles = makeStyles((theme) => ({
    root2: {
        marginTop: 10,
        width: '96%',
        maxWidth: 360,
        backgroundColor: theme.palette.action.selected,
        borderRadius: 10,
        paddingLeft: 10,
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    formControl: {
        fontsize:15,
        margin: theme.spacing(0),
        minWidth: 50,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root3: {
        '& > *': {
          margin: theme.spacing(2),
        },
      },
}));
function useMenu(){
    const [me,setMe]=useState([])
    useEffect(()=>{
        db.collection("staff")
        .onSnapshot((snapshot)=>{
            const newMe = snapshot.docs.map((doc)=>({
                id:doc.id,
                ...doc.data()
            }))
            function compare(a, b) {
                const bandA = a.num;
                const bandB = b.num;
              
                let comparison = 0;
                if (bandA > bandB) {
                  comparison = 1;
                } else if (bandA < bandB) {
                  comparison = -1;
                }
                return comparison;
            }
            newMe.sort(compare)
            setMe(newMe)
        })
    },[])
    return me
}


export function StaffControl(){
    const me=useMenu()
    console.log(me)
    const classes = useStyles();
    const [menuu,setMenu]=useState([])
    const [show,setShow] = useState(true)
    const [hide,setHide] = useState(false)
    const [handleName,setHandleName]=useState("")
    const [handlePercent,setHandlePercent]=useState("")
    const [handleType,setHandleType]=useState("")

    let inputname=""
    let inputpercent=""
    let inputtype=""
    let inputcate=""
    const [changeName,setChangeName]=useState("")
    const [changePrice,setChangePrice]=useState("")
    const [changeType,setChangeType]=useState("")
    const [changeCate,setChangeCate]=useState("")
    const [changeFix,setChangeFix]=useState("")
    useEffect(()=>{
        let fbmenu=[]
        let fbmenuid=[]
        db.collection("staff")
        .get()
        .then((onSnapshot) => {
            onSnapshot.forEach((doc) => {
                fbmenu.push(doc.data())
                fbmenuid.push(doc.id)
            })
            for(let i=0;i<fbmenu.length;i++){
                fbmenu[i].id=fbmenuid[i]
            }
            function compare(a, b) {
                const bandA = a.num;
                const bandB = b.num;
              
                let comparison = 0;
                if (bandA > bandB) {
                  comparison = 1;
                } else if (bandA < bandB) {
                  comparison = -1;
                }
                return comparison;
            }
            fbmenu.sort(compare)
            setMenu(fbmenu)
        })
    },[])    
    function shownhide(){
        setShow(!show)
        setHide(!hide)

    }
    let newmenu= JSON.parse(JSON.stringify(menuu))  
    console.log(newmenu)
    function handleChangeName(event,index){
    // let newmenu= JSON.parse(JSON.stringify(menuu))        
        let changename=[]
        console.log(event.target.value)
        console.log(index)
        changename.push(event.target.value)
        console.log(changename)
        // setChangeType(event.target.value)
        if(changename.length>0){
            newmenu[index].name=changename[0]
        }

    }
    function handleChangeCategory(event,index){
        // let newmenu= JSON.parse(JSON.stringify(menuu))
        let changecate=[]
        console.log(event.target.value)
        console.log(index)
        changecate.push(event.target.value)
        console.log(changecate)
        // setChangeCate(event.target.value)
        if(changecate.length>0){
            newmenu[index].category=changecate[0]
        }
    }
    let changepercent=""
    function handleChangePercent(event,index){
        // let newmenu= JSON.parse(JSON.stringify(menuu))
        console.log(event.target.value)
        console.log(index)
        changepercent=event.target.value
        // setChangeName(event.target.value)
        console.log(changepercent)
        if(changepercent.length>0){
            newmenu[index].percent=changepercent
        }
    }
    let changeprice=""
    function handleChangeSPrice(event,index){
        // let newmenu= JSON.parse(JSON.stringify(menuu))
        console.log(event.target.value)
        console.log(index)
        changeprice=event.target.value
        // setChangePrice(event.target.value)
        console.log(changeprice)
        if(changeprice.length>0){
            newmenu[index].price=changeprice
        }
        console.log(newmenu[index])
    }    

    let changefix=""
    function handleChangeSFix(event,index){
        // let newmenu= JSON.parse(JSON.stringify(menuu))
        console.log(event.target.value)
        console.log(index)
        // setChangeFix(event.target.value)
        changefix=event.target.value
        console.log(changefix)
        if(changefix.length>0){
            newmenu[index].fix=Number(changefix)
        }
    }
    function handleAgree(index){
        
        console.log(index,newmenu)
        let changeRef=db.collection("staff").doc(newmenu[index].id)
        return changeRef.update({
            percent:newmenu[index].percent,
            name:newmenu[index].name,
            type:newmenu[index].type,
            num:newmenu[index].num,
        })
        .then(function () {
            setMenu(newmenu)
            console.log("Document successfully updated!");
            db.collection("staff").doc(newmenu[index].id)
              .onSnapshot(function (doc) {
                console.log("Current data: ", doc.data());
              });  
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
    }
    function handleDisagree(index){
        let fbmenu=[]
        let fbmenuid=[]
        db.collection("staff")
        .get()
        .then((onSnapshot) => {
            onSnapshot.forEach((doc) => {
                fbmenu.push(doc.data())
                fbmenuid.push(doc.id)
            })
            for(let i=0;i<fbmenu.length;i++){
                fbmenu[i].id=fbmenuid[i]
            }
            setMenu(fbmenu)
        })
    }
    //Modal
    var subtitle;
  const [modalIsOpen,setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
 
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.back = '#e2cc8e';
    subtitle.style.textAlign ="center";
  }
 
    function closeModal(){
    setIsOpen(false);
    }
    function handleNewName(event) {
        console.log(event.target.value)
        inputname = event.target.value
        setHandleName(event.target.value)
    }

    function handleNewPercent(event) {
        console.log(event.target.value)
        inputpercent = Number(event.target.value)
        setHandlePercent(Number(event.target.value))
    }    
    function handleNewType(event){
        console.log(event.target.value)
        inputtype= event.target.value
        setHandleType(event.target.value)
    }
    function handleNewYes(){
        let stt=menuu.length
        db.collection("staff").add({
            num: stt,
            name: handleName,
            percent: Number(handlePercent),
            type: handleType,
        })
            .then(function (docRef) {
                console.log("Document written with ID: ", docRef.id);
                db.collection("staff").doc(docRef.id)
                    .onSnapshot(function (doc) {
                        console.log("Current data: ", doc.data());
                    });

            })
            .catch(function (error) {
                console.error("Error adding document: ", error);
            });
            setIsOpen(false);
    }
    return <div>
        <div style={{marginLeft:"15px",marginBottom:"5px"}}>
        <Button color="primary" variant="contained" onClick={openModal}>Thêm mới <AddCircleOutlineIcon fontSize="small"/> </Button>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
        >
              
        <div id="removebutton">
            <Button onClick={closeModal}><CloseIcon color="secondary"/></Button> 
        </div>
                <table id="historytable">
                    <tr>
                        <th colSpan="2" ref={_subtitle => (subtitle = _subtitle)}>Nhân Viên Mới</th>
                    </tr>
                    <tr>
                        <td>Stt: </td>
                        <td>{menuu.length} </td>
                    </tr>
                    <tr>
                        <td>Tên nhân viên: </td>
                        <td><input onChange={handleNewName} type="text" id="inputname" placeholder="Tên nhân viên"/></td>
                    </tr>
                    <tr>
                        <td>Loại: </td>
                        <td>
                        <Select
                        labelId="demo-simple-select-label"
                        id="type"
                        defaultValue={inputtype}
                        onChange={handleNewType}
                        >
                        <MenuItem value="fix">bình thường</MenuItem>
                        <MenuItem value="partner">ăn chia</MenuItem>
                        <MenuItem value="other">khác</MenuItem>
                        </Select>   
                        </td>
                    </tr>
                    <tr>
                        <td>Phần trăm: </td>
                        <td>
                       <input type="text" placeholder="phần trăm" onChange={handleNewPercent} ></input>
                        </td>
                    </tr>
                </table>
                <br></br>
                <div style={{textAlign:"right"}}>
                <Button onClick={handleNewYes} variant="contained" color="primary">Thêm mới <CheckCircleRoundedIcon/></Button>  <Button variant="contained" color="primary">hủy <CancelIcon/></Button>
                </div>
            </Modal>
        </div>

        <table id="historytable" style={{width:"98%",margin:"auto"}}>
            <tr>
                <th>Stt</th>
                <th>Tên</th>
                <th>Loại</th>
                <th>Phần trăm</th>
            </tr>
            {me.map((me,index)=>(

                <tr>
                    <td>{me.num} </td>
                    <td>
                        {show?
                        <p>{me.name}</p>
                        :null}
                        {hide?
                        <p><input type="text" defaultValue={me.name} style={{width:"80px",fontSize:"16px"}}
                        onChange={(e)=>{handleChangeName(e,index)}} /> </p>
                        :null}
                    </td>
                    <td>
                        {show?
                        <p>{me.type}</p>
                        :null}
                        {hide?
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={me.category}
                                    onChange={(e) => { handleChangeCategory(e,index) }}
                                >
                                        <MenuItem value="fix">bình thường</MenuItem>
                                        <MenuItem value="partner">ăn chia</MenuItem>
                                </Select>
                            </FormControl>
                        :null}
                    </td>
                    <td>
                        {show?
                        <p>{me.percent}
                        <IconButton size="small" onClick={shownhide}><EditIcon/></IconButton>
                        </p>
                        :null}
                        {hide?
                        <p>
                            <input type="text" defaultValue={me.percent} style={{width:"80px",fontSize:"16px"}}
                            onChange={(e)=>{handleChangePercent(e,index)}} /> 
                            <IconButton size="small" onClick={()=>{shownhide();handleAgree(index) }}><CheckIcon/></IconButton> 
                            <IconButton size="small" onClick={()=>{shownhide();handleDisagree(index)}}><ClearIcon/></IconButton> 
                        </p>
                        :null}
                    </td>
                </tr>
            ))}
        </table>
    </div>
}