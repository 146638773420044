import React, { useEffect, useRef } from 'react'
import { useState } from 'react'

import { TimeReceipt, ScrollToTop } from './Time'
import app from '../Config/firebase.config'

import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';

import ListItemText from '@material-ui/core/ListItemText';

import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import "firebase/firestore";
import firebase from 'firebase/app'
import { firebaseConfig } from '../Config/firebase.config'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useReactToPrint } from 'react-to-print'
import { HistoryTable } from './HistoryTable'
import { getMonth } from 'date-fns/fp';

try {
    firebase.initializeApp(firebaseConfig);
} catch (e) {
    console.log(e.message)
}

const db = firebase.firestore();

var moment = require('moment');
moment().format();

const useStyles = makeStyles((theme) => ({
    root2: {
        width: "auto",
        maxWidth: "95%",
        backgroundColor: theme.palette.action.selected,
        borderRadius: 10,
        padding: 10,
        margin: theme.spacing(1),
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    formControl: {
        fontsize: 20,
        margin: theme.spacing(0),
        minWidth: 50,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root3: {
        '& > *': {
            margin: theme.spacing(2),
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
    margin1: {
        margin: theme.spacing(0.5),
        borderRadius: 10,
    },
    margin2: {
        textAlign: 'right',
    },
}));

let tong = ""
let dv = ""
let ngay = ""
let tongngay = ""
let tienmat = ""
let tongchi = ""
let tongchietkhau = ""
let chuyenkhoan = ""
let conlai = ""
let danhsachchi = ""
let danhsachchietkhau = ""
let chietkhaunv = ""
let giam=""


function numberWithCommas(x) {
    return x.toLocaleString()
}                               
//thu nghiem conditional rendering


export function Body() {
    let date = moment(new Date()).format("DD/MM/YYYY")
    let time = new Date().toLocaleTimeString()
    //
    const componentRef = useRef();
    const componentRefEnd = useRef();
    const componentRefShare = useRef();
    const componentToPrintMomo = useRef();
    const componentToPrintSacombank = useRef();


    const handlePrintMomo = useReactToPrint({
        content: () => componentToPrintMomo.current,
    })
    const handlePrintSacombank = useReactToPrint({
        content: () => componentToPrintSacombank.current,
    })

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })
    const handlePrintEndDay = useReactToPrint({
        content: () => componentRefEnd.current,
    })
    const handlePrintShare = useReactToPrint({
        content: () => componentRefShare.current,
    })

    //
    var user = firebase.auth().currentUser;

    console.log(user.email)
    console.log(user.uid)
    // console.log(moment(new Date()).format("DD/MM/YYYY"))
    const classes = useStyles();
    const [nhoadon, setNhoadon] = useState([])
    const [hoadon, setHoadon] = useState([])
    //công thức cộng
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    //cộng chiết khấu
    const [sharee, setShare] = useState([])
    //cộng tổng tiền
    const [count, setCalculator] = useState([])
    const [menuu, setMenu] = useState([])

    const [employ, setEmploy] = useState([])
    const [show, setShow] = useState(true)
    const [hide, setHide] = useState(false)
    //giam phan tram
    const [discount,setDiscount]=useState(0)
    let handledis=""
    function handleDiscount(event){
        console.log(event.target.value)
        handledis=event.target.value
    }
    function applydiscount(){
        console.log(handledis)
        setCalculator(count*((100-handledis)/100))
        setDiscount(handledis)
    }


    let male = []
    let female = []
    let employy = []
    let helper = []
    let setprice = 0
    let setshare = 0
    const [totalRev, setTotalRev] = useState([0])
    const [totalShare, setTotalShare] = useState([0])

    //Lịch sử chi
    const [reportShare, setReportShare] = useState([{
        name: "",
        sale: "",
    }])
    const [listTotal, setListTotal] = useState("")
    const [listItem, setListItem] = useState([{
        note: 0,
        price: 0,
    }])


    const [startDate3, setStartDate3] = useState(new Date());
    const [date3, setDate3] = useState(date)

    //checkbox thanh toán tiền mặt hay thẻ
    const [checked, setChecked] = React.useState(false);
    const [card, setCard] = useState(count)
    const [cashh, setCashh] = useState(0)
    const errorMsgCardInput = ('phải nhập số')
    const errorMsgNumberbigger = ('phải nhỏ hơn tổng tiền thanh toán')
    const handleChangeCard = (event) => {
        const cardValue= Number(event.target.value)
        console.log(Number(cardValue))
        console.log(isNaN(cardValue))
        const cashhh =count-cardValue
        if(Number.isNaN(cardValue) === false ){
                setCard(cardValue)
                setCashh(cashhh)
        } else {
            
        }
    }
    const handleChange = (event) => {
        setChecked(event.target.checked);
        setCard(count)
    };
    //cộng riêng tiền mặt vs tiền thẻ
    const [cash, setCash] = useState([0])
    const [credit, setCredit] = useState([0])

    const [receipt, setReceipt] = useState("")
    //menu firebase
    useEffect(() => {
        var user = firebase.auth().currentUser;
        let fbmenu = []
        let fbemp = []
        let fbempid=[]
        db.collection("menu")
            .get()
            .then((onSnapshot) => {
                onSnapshot.forEach((doc) => {
                    fbmenu.push(doc.data())

                })
                setMenu(fbmenu)
            })
        db.collection("staff")
            .get()
            .then((onSnapshot) => {
                onSnapshot.forEach((doc) => {
                    fbemp.push(doc.data())
                    fbempid.push(doc.id)

                })

                for(let i=0;i<fbemp.length;i++){
                    fbemp[i].id=fbempid[i]
                }
                console.log(fbemp)
                function compare(a, b) {
                    const bandA = a.num;
                    const bandB = b.num;

                    let comparison = 0;
                    if (bandA > bandB) {
                        comparison = 1;
                    } else if (bandA < bandB) {
                        comparison = -1;
                    }
                    return comparison;
                }
                fbemp.sort(compare)
                setEmploy(fbemp)
            })

            function compare(a, b) {
                const bandA = a.time;
                const bandB = b.time;
    
                let comparison = 0;
                if (bandA > bandB) {
                    comparison = 1;
                } else if (bandA < bandB) {
                    comparison = -1;
                }
                return comparison;
            }
            db.collection("receipt").where("date","==",date3)
                .onSnapshot((onSnapshot)=>{
                    let cashcount = [0]
                    let creditcount = [0]
                    let receipthis = []
                    let idhis = []
                    let nhanvien = []
                    let torev = [0];
                    let tosha = [0];
                    let fbreceipt = []
                    onSnapshot.forEach((doc) => {
                        fbreceipt.push(doc.data())
                        console.log(fbreceipt,receipthis)
                        if (user.email === "phat.phamqt@gmail.com" || user.email === "cn2@gmail.com") {
                            if (doc.data().branch === 2) {
                                idhis.push(doc.id)
                                receipthis.push(doc.data())
                                nhanvien.push(doc.data().share)
                                torev.push(doc.data().total)
                                tosha.push(doc.data().share)
                                if (doc.data().credit === true) {
                                    creditcount.push(doc.data().card)
                                } else {
                                    cashcount.push(doc.data().total)
                                }

                            }
                        }
                        if (user.email === "cn1@gmail.com") {
                            if (doc.data().branch === 1) {
                                idhis.push(doc.id)
                                receipthis.push(doc.data())
                                nhanvien.push(doc.data().share)
                                torev.push(doc.data().total)
                                tosha.push(doc.data().share)
                                if (doc.data().credit === true) {
                                    creditcount.push(doc.data().total)
                                } else {
                                    cashcount.push(doc.data().total)
                                }
                            }
                        }
                    })
                    for (let i = 0; i < idhis.length; i++) {
                        receipthis[i].id = idhis[i]
                    }
                    for (let i = 0; i < nhanvien.length; i++) {
                        receipthis[i].share = nhanvien[i]
                    }
                    setSameDayHis(receipthis.sort(compare))
                    setTotalRev(torev.reduce(reducer))
                    setTotalShare(tosha.reduce(reducer))
                    setCash(cashcount.reduce(reducer))
                    setCredit(creditcount.reduce(reducer))
                    setReceipt(fbreceipt)
                    
                })

    }, [])

    function numberWithCommas(x) {
        return x.toLocaleString()
    }
    //bật tắt chỉnh sửa
    function shownhide() {
        setShow(!show)
        setHide(!hide)
    }
    //phân loại tóc nam và nữ
    menuu.map(nam => {
        if (nam.category === "NAM" && nam.stat === "on") {
            male.push(nam)
        }
        return male
    })
    menuu.map(nu => {
        if (nu.category === "NU" && nu.stat === "on") {
            female.push(nu)
        }
        return female
    })
    //lịch sử hóa đơn trong ngày
    const [samedayhis, setSameDayHis] = useState([])
    const [samedayreport, setSameDayReport] = useState([])
    const [dayend, setDayend] = useState([])
    const [reporter, setReporter] = useState("")
    //useEffect samedayhis,employ,reporter
    useEffect(() => {
        let totalshare = []
        let rpshare = []
        for (let i = 0; i < employ.length; i++) {
            totalshare.push({
                name: employ[i].name,
                sale: 0,
                id:employ[i].id,
            })
        }
        for (let i = 0; i < samedayhis.length; i++) {
            for (let j = 0; j < samedayhis[i].services.length; j++) {
                for (let k = 0; k < samedayhis[i].services[j].chietkhau.length; k++) {
                    for (let l = 0; l < totalshare.length; l++) {
                        if (totalshare[l].name === samedayhis[i].services[j].chietkhau[k].name) {
                            totalshare[l].sale += samedayhis[i].services[j].chietkhau[k].tienchietkhau
                        }
                    }
                }

                for (let k = 0; k < samedayhis[i].services[j].help.length; k++) {
                    for (let l = 0; l < totalshare.length; l++) {
                        if (totalshare[l].name === samedayhis[i].services[j].help[k].name) {
                            totalshare[l].sale += samedayhis[i].services[j].help[k].tienchietkhau
                        }
                    }
                }
            }
        }
        console.log(totalshare)
        setSameDayReport(totalshare)
        for (let i = 0; i < totalshare.length; i++) {
            if (totalshare[i].sale !== 0) {
                rpshare.push(totalshare[i])
            }
        }
        console.log(rpshare)
        setReportShare(rpshare)

        let newreport = JSON.parse(JSON.stringify(samedayhis))
        let newshare = [{
            name: reporter,
            sale: 0,
            services: [],
            servicess: []
        }]

        console.log(newreport, newshare)
        let ser = []
        for (let a = 0; a < newshare.length; a++) {

            for (let b = 0; b < newreport.length; b++) {
                for (let c = 0; c < newreport[b].services.length; c++) {
                    for (let d = 0; d < newreport[b].services[c].chietkhau.length; d++) {
                        // if(newshare[a].name!=="Hiền"){
                        if (newshare[a].name === newreport[b].services[c].chietkhau[d].name) {
                            ser.push({
                                name: newreport[b].services[c].name,
                                price: newreport[b].services[c].price,
                                sale: newreport[b].services[c].chietkhau[d].tienchietkhau,
                                time: newreport[b].time
                            })
                            newshare[a].sale += newreport[b].services[c].chietkhau[d].tienchietkhau
                            console.log(ser)
                            // newshare[a].services = ser
                        }
                    // }
                    }
                }
            }
        }
        for (let z = 0; z < newshare.length; z++) {
            // let ser = []
            for (let e = 0; e < newreport.length; e++) {
                for (let f = 0; f < newreport[e].services.length; f++) {
                    for (let g = 0; g < newreport[e].services[f].help.length; g++) {
                        // if(newshare[z].name!=="Hiền"){

                        
                        if (newshare[z].name === newreport[e].services[f].help[g].name) {
                            ser.push({
                                name: newreport[e].services[f].name,
                                price: newreport[e].services[f].price,
                                sale: newreport[e].services[f].help[g].tienchietkhau,
                                time: newreport[e].time
                            })
                            newshare[z].sale += newreport[e].services[f].help[g].tienchietkhau
                            console.log(ser)
                            // newshare[z].servicess = ser
                        }
                    // }
                    }
                }
            }
        }
        function compare(a, b) {
            const bandA = a.time;
            const bandB = b.time;

            let comparison = 0;
            if (bandA > bandB) {
                comparison = 1;
            } else if (bandA < bandB) {
                comparison = -1;
            }
            return comparison;
        }
        ser.sort(compare)
        newshare[0].servicess = ser
        console.log(newshare)
        setDayend(newshare)
    }, [samedayhis, employ, reporter])
    useEffect(() => {

        //
        let share = [0]
        if (hoadon.length > 0) {
            for (let i = 0; i < hoadon.length; i++) {
                let plusshare = hoadon[i].chietkhau
                let plushelp = hoadon[i].help
                for (let b = 0; b < plusshare.length; b++) {
                    share.push(plusshare[b].tienchietkhau)
                }
                for (let c = 0; c < plushelp.length; c++) {
                    share.push(plushelp[c].tienchietkhau)
                }
            }
            //
            let coumt = [0]
            for (let i = 0; i < hoadon.length; i++) {
                coumt.push(Number(hoadon[i].price))
            }
            setShare(share.reduce(reducer))
            setCalculator(coumt.reduce(reducer))
            //

            let newhoadon = JSON.parse(JSON.stringify(hoadon));
            console.log(newhoadon)
            for (let i = 0; i < newhoadon.length; i++) {
                newhoadon[i].stt = i
            }
            setNhoadon(newhoadon)
        } else {
            setShare(0)
            setCalculator(0)
            let newhoadon = JSON.parse(JSON.stringify(hoadon));
            for (let i = 0; i < newhoadon.length; i++) {
                newhoadon[i].stt = i
            }
            setNhoadon(newhoadon)
        }
        
    }, [hoadon])

    useEffect(()=>{
        let cashcount = [0]
        let creditcount = [0]
        let expTotal = [0]
        let expItem = []
        let receipthis = []
        let idhis = []
        let nhanvien = []
        let torev = [0];
        let tosha = [0];
        let fbreceipt = []
        function compare(a, b) {
            const bandA = a.time;
            const bandB = b.time;

            let comparison = 0;
            if (bandA > bandB) {
                comparison = 1;
            } else if (bandA < bandB) {
                comparison = -1;
            }
            return comparison;
        }
        db.collection("receipt").where("date","==",date3)
            .get()
            .then((onSnapshot) => {
                onSnapshot.forEach((doc) => {
                    fbreceipt.push(doc.data())
                    if (user.email === "phat.phamqt@gmail.com" || user.email === "cn2@gmail.com") {
                        if (doc.data().branch === 2) {
                            idhis.push(doc.id)
                            receipthis.push(doc.data())
                            nhanvien.push(doc.data().share)
                            torev.push(doc.data().total)
                            tosha.push(doc.data().share)
                            if (doc.data().credit === true) {
                                // creditcount.push(doc.data().total)
                                if('card' in doc.data()){
                                    creditcount.push(doc.data().card)
                                    cashcount.push(doc.data().cash)
                                } else {
                                    creditcount.push(doc.data().total)
                                }
                            } else {
                                cashcount.push(doc.data().total)
                            }
                        }
                    }
                    if (user.email === "cn1@gmail.com") {
                        if (doc.data().branch === 1) {
                            idhis.push(doc.id)
                            receipthis.push(doc.data())
                            nhanvien.push(doc.data().share)
                            torev.push(doc.data().total)
                            tosha.push(doc.data().share)
                            if (doc.data().credit === true) {
                                creditcount.push(doc.data().total)
                            } else {
                                cashcount.push(doc.data().total)
                            }
                        }
                    }
                })
                for (let i = 0; i < idhis.length; i++) {
                    receipthis[i].id = idhis[i]
                }
                for (let i = 0; i < nhanvien.length; i++) {
                    receipthis[i].share = nhanvien[i]
                }
                setSameDayHis(receipthis.sort(compare))
                setTotalRev(torev.reduce(reducer))
                setTotalShare(tosha.reduce(reducer))
                setCash(cashcount.reduce(reducer))
                setCredit(creditcount.reduce(reducer))
                setReceipt(fbreceipt)
            })
        //lịch sử chi
        let expenseid=[]
        db.collection("expense").where("date","==",date3)
            .get()
            .then((onSnapshot) => {
                onSnapshot.forEach((doc) => {
                    expenseid.push(doc.id)
                    if (user.email === "phat.phamqt@gmail.com" || user.email === "cn2@gmail.com") {
                        if (doc.data().branch === 2) {
                            expTotal.push(Number(doc.data().price))
                            expItem.push(doc.data())
                        }
                    }
                    if (user.email === "cn1@gmail.com") {
                        if (doc.data().branch === 1) {
                            expTotal.push(Number(doc.data().price))
                            expItem.push(doc.data())
                        }
                    }
                })
                for(let i=0;i<expItem.length;i++){
                    expItem[i].id=expenseid[i]
                }
                console.log(expItem.sort(compare))
                setListItem(expItem.sort(compare))
                setListTotal(expTotal.reduce(reducer))
            })
    },[date3,user.email])
    function countbadge(nu, index) {
        let newmenu = [...menuu]
        for (let i = 0; i < menuu.length; i++) {
            if (nu.num === newmenu[i].num) {
                newmenu[i].chietkhau = [{}]
                newmenu[i].called += 1
                setMenu(newmenu)
            }
        }

        let c = []
        let d = JSON.parse(JSON.stringify(menuu));
        for (let b = 0; b < d.length; b++) {
            if (d[b].num === nu.num) {
                let chietkhau = [{
                    name: employ[0].name,
                    tienchietkhau: (d[b].price * employ[0].percent / 100),
                }]
                let help = [{
                    name: employ[0].name,
                    tienchietkhau: 0,
                }]
                //
                if (nu.type === 'cut') {
                    d[b].help = help
                    d[b].chietkhau = chietkhau
                } else {
                    d[b].chietkhau = chietkhau
                    d[b].help = []
                }

                let f = JSON.parse(JSON.stringify(d[b]));
                c.push(f)
            }
        }
        setHoadon(hoadon => [...hoadon, c[0]])
    }
    function minusbadge(n, index) {
        console.log(n, hoadon, nhoadon)
        for (let i = 0; i < menuu.length; i++) {
            if (n.num === menuu[i].num && menuu[i].called > 0) {
                let newmenu = JSON.parse(JSON.stringify(menuu));
                newmenu[i].called -= 1
                setMenu(newmenu)
            }
        }
        let newhoadon = JSON.parse(JSON.stringify(hoadon));
        let newnhoadon = JSON.parse(JSON.stringify(nhoadon));
        newhoadon.splice(index, 1)
        newnhoadon.splice(index, 1)
        console.log(newnhoadon)
        setHoadon(newhoadon)
        setNhoadon(newnhoadon)
    }
    function checkout() {


        //clean menu
        for (let i = 0; i < menuu.length; i++) {
            let newmenu = [...menuu]
            newmenu[i].called = 0
            setMenu(newmenu)
        }
        //clean receipt
        let blank = []
        setHoadon(blank)
        setNhoadon(blank)
        setCalculator(blank)
        setShare(blank)
        setCard(0)
        setCashh(0)
        //add firebase
        for (let i = 0; i < nhoadon.length; i++) {
            for (let b = 0; b < nhoadon[i].chietkhau.length; b++) {
                if (nhoadon[i].chietkhau[b].tienchietkhau === 0) {
                    nhoadon[i].chietkhau.splice(b, 1)
                }
            }
            for (let b = 0; b < nhoadon[i].help.length; b++) {
                if (nhoadon[i].help[b].tienchietkhau === 0) {
                    nhoadon[i].help.splice(b, 1)
                }
            }
        }
        if (user.email === "phat.phamqt@gmail.com" || user.email === "cn2@gmail.com") {
            if(nhoadon.length>0){
                db.collection("receipt").add({
                    branch: 2,
                    time: time,
                    date: date,
                    services: nhoadon,
                    total: count,
                    share: sharee,
                    credit: checked,
                    num: receipt.length,
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    cash: cashh,
                    card: card,
                    remuneration: shareDetail,
                })
                    .then(function (docRef) {
                        console.log("Document written with ID: ", docRef.id);
                        db.collection("receipt").doc(docRef.id)
                            .onSnapshot(function (doc) {
                                console.log("Current data: ", doc.data());
                            });
    
                    })
                    .catch(function (error) {
                        console.error("Error adding document: ", error);
                    });
            }

        }
        if (user.email === "cn1@gmail.com") {
            if(nhoadon.length>0){            
                db.collection("receipt").add({
                branch: 1,
                time: time,
                date: date,
                services: nhoadon,
                total: count,
                share: sharee,
                credit: checked,
                num: receipt.length,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                cash: cashh,
                card: card,
                remuneration: shareDetail,
            })
                .then(function (docRef) {
                    console.log("Document written with ID: ", docRef.id);
                    db.collection("receipt").doc(docRef.id)
                        .onSnapshot(function (doc) {
                            console.log("Current data: ", doc.data());
                        });

                })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                });}
        }

    }
    function moreDoer(index) {
        console.log(index)
        let addDoer = JSON.parse(JSON.stringify(hoadon));
        addDoer[index].chietkhau.push({
            name: employ[0].name,
            tienchietkhau: (addDoer[index].price * employ[0].percent / 100)
        })
        setHoadon(addDoer)
    };
    const handleChangeDoer = (event, index, dex) => {
        for (let i = 0; i < employ.length; i++) {
            if (event.target.value === employ[i].name) {
                employy.push(employ[i])
            }
        }
        console.log(employy)
        let newhoadon = [...hoadon]
        if (employy.length > 0) {
            if (employy[0].type === "fix") {
                let a = newhoadon[index].chietkhau
                if (employy[0].name === "trống") {
                    a[dex] = {
                        name: employy[0].name,
                        tienchietkhau: 0
                    }
                } else {
                    a[dex] = {
                        name: employy[0].name,
                        tienchietkhau: (newhoadon[index].fix / a.length)
                    }
                }
            } else if (employy[0].type === "partner") {
                if (newhoadon[index].type==="cut" || newhoadon[index].type==="che"){
                    let a = newhoadon[index].chietkhau
                    a[dex] = {
                        name: employy[0].name,
                        tienchietkhau: (newhoadon[index].price * (employy[0].percent/100) / a.length)
                    }
                } else if (newhoadon[index].type==="other"){
                    let a = newhoadon[index].chietkhau
                    a[dex] = {
                        name: employy[0].name,
                        tienchietkhau: (newhoadon[index].price * (0.1) / a.length)
                    }
                }
            }
        } else {
                if(newhoadon[index].type==="cut" || newhoadon[index].type==="che"){
                    newhoadon[index].chietkhau = {
                        name: employ[0].name,
                        tienchietkhau: (newhoadon[index].price * 0.2 / newhoadon[index].chietkhau.length)
                    }
                } else if (newhoadon[index].type==="other"){
                    newhoadon[index].chietkhau = {
                        name: employ[0].name,
                        tienchietkhau: (newhoadon[index].price * 0.1 / newhoadon[index].chietkhau.length)
                }

        }}
        setHoadon(newhoadon)
    };
    function moreHelper(index) {
        let addDoer = JSON.parse(JSON.stringify(hoadon));
        addDoer[index].help.push({
            name: employ[0].name,
            tienchietkhau: (addDoer[index].price * employ[0].percent / 100)
        })
        setHoadon(addDoer)
    };
    const handleChangeHelper = (event, index, dex) => {
        for (let i = 0; i < employ.length; i++) {
            if (event.target.value === employ[i].name) {
                helper.push(employ[i])
            }
        }
        let newhoadon = [...hoadon]
        if (helper.length > 0) {
            if (newhoadon[index].type !== "che") {
                if (helper[0].type === "fix") {
                    let a = newhoadon[index].help
                    a[dex] = {
                        name: helper[0].name,
                        tienchietkhau: (newhoadon[index].fix / a.length)
                    }
                } else if (helper[0].type === "partner") {
                    let a = newhoadon[index].help
                    a[dex] = {
                        name: helper[0].name,
                        tienchietkhau: (newhoadon[index].fix / a.length)
                    }
                }
            } else {
                if (helper[0].type === "fix") {
                    let a = newhoadon[index].help
                    a[dex] = {
                        name: helper[0].name,
                        tienchietkhau: (newhoadon[index].fix / a.length)
                    }
                } else if (helper[0].type === "partner") {
                    let a = newhoadon[index].help
                    a[dex] = {
                        name: helper[0].name,
                        tienchietkhau: (newhoadon[index].price * 0.2 / a.length)
                    }
                }
            }


        } else {
            newhoadon[index].help = {
                name: employ[0].name,
                tienchietkhau: (newhoadon[index].price * employ[0].percent / 100)
            }
        }
        setHoadon(newhoadon)
    };
    const handleChange1 = (event) => {
        setprice = event.target.value
    }
    const handlePrice = (hoa, index) => {
        let newhoadon = JSON.parse(JSON.stringify(hoadon));
        if (setprice !== 0) {
            newhoadon[index].price = Number(setprice)
            setHoadon(newhoadon)
            // shownhide()
        } else {
            // shownhide()
        }
    }
    const handleChange2 = (event) => {
        setshare = event.target.value
    }
    const handleshare = (index, dex) => {
        let newhoadon = JSON.parse(JSON.stringify(hoadon));
        newhoadon[index].chietkhau[dex].tienchietkhau = Number(setshare)
        setHoadon(newhoadon)
        document.getElementById("sharemoney").value=""
    }

    function handleReporter(event) {
        console.log(event.target.value)
        setReporter(event.target.value)
    }
    let left = totalRev - (listTotal + totalShare + credit)
    function handleChange3(value) {
        const valueOfInput = moment(value).format("DD/MM/YYYY");
        let ad = valueOfInput
        console.log(valueOfInput)
        setStartDate3(value)
        setDate3(ad)
    }
    tong = numberWithCommas(count).concat(".000")
    dv = nhoadon
    ngay = date3
    tongngay = totalRev
    tienmat = cash
    tongchi = listTotal
    tongchietkhau = totalShare
    chuyenkhoan = credit
    conlai = left
    danhsachchi = listItem
    danhsachchietkhau = reportShare
    chietkhaunv = dayend
    giam=discount


    function countShareIndi(nhoadon){
        let arr = []
        let result = []

        nhoadon.forEach((hoadon)=>{
            console.log(hoadon)
            hoadon[key].forEach((rec) => {
                arr=[...arr,rec]
            })
        })
        console.log(arr)
        arr.forEach((x)=>{
            //check if there is any object in result which contain the key2 value
            if(result.some((val)=>{ return val[key2]===x[key2]})){
            //if yes, then count the number contain in key3
                result.forEach((k)=>{
                    if(k[key2] === x[key2]){
                        k[key3] += x[key3]
                    }
                })
            } else {
            //if not, then create a new object initialize it with the present iteration key's value and set the "tienchietkhau"'s value same as key3's value
                let a={}
                // a[key2]=x[key2]
                // a[key3]=x[key3]
                a[x[key2]]=x[key3]
                // a[key3]=x[key3]
                result.push(a)
            }
        })
        return result
    }
    let key="chietkhau"
    let key2="name"
    let key3="tienchietkhau"
    const shareDetail = countShareIndi(nhoadon)

    console.log(shareDetail)
    


    return <div id="main">
        {/* phan 1 */}
        <div className="row header" style={{ width: "100%", height: "auto", maxHeight: "90px",marginTop:"5px" }} >

            <div className="col-3" style={{ height: "36px" }}>
                <DatePicker id="start" selected={startDate3} onChange={(value) => handleChange3(value)} />
                {/* <Button variant="contained" color="primary" onClick={handlePrintAn}>AN</Button> */}
                <Button variant="contained" color="primary" onClick={handlePrintSacombank}>ACB</Button>
                {/* <Button variant="contained" color="primary" onClick={handlePrintMomo}>MOMO</Button> */}
                {/* <Button variant="contained" color="primary" onClick={handlePrintInfo}>KBYT </Button> */}
            </div>

            <div className="col-9" >
                <FormControl className={classes.formControl}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={reporter}
                        onChange={(e) => { handleReporter(e) }}
                    >
                        {employ.map(em => (
                            <MenuItem value={em.name}>{em.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button variant="contained" color="primary" onClick={handlePrintShare}>In báo cáo</Button>
                {/* &nbsp;&nbsp; */}
                <Button variant="contained" color="primary" onClick={handlePrintEndDay}>In cuối ngày</Button>
                {/* &nbsp;&nbsp; */}
                <Button color="secondary" className={classes.margin2} size="medium" variant="contained" onClick={() => app.auth().signOut()}>Sign out</Button>
                {/* &nbsp;&nbsp;     */}
            </div>
        </div>
        {/* phan 2 */}
        <div id="body" >
            <div id="menu" >
                <Box id="nam" className={classes.root} >
                    {male.map((nam, index) => (
                        <Badge key={nam.num} color="secondary" badgeContent={nam.called} >
                            <Button size="small" variant="contained" color="primary"
                                onClick={() => {
                                    countbadge(nam, index);
                                }} >
                                {nam.name} {nam.price}
                            </Button>
                        </Badge>
                    ))}
                </Box>
                <Box id="nu" className={classes.root}>
                    {female.map((nu, index) => (
                        <Badge key={nu.num} color="secondary" badgeContent={nu.called}>
                            <Button size="small" variant="contained" color="primary"
                                onClick={() => {
                                    // addreceipt(nu);
                                    countbadge(nu, index);
                                }} className="list">
                                {nu.name} {nu.price}
                            </Button>
                        </Badge>
                    ))}
                </Box>
            </div>

            <div id="hoadon" >
                <div id="checkout">
                    <Button variant="contained" size="small" color="primary" className={classes.margin} onClick={checkout} >Tính tiền</Button>
                &nbsp; &nbsp;
                {/* <Button variant="contained" size="small" color="primary" className={classes.margin} onClick={openModal}>In hóa đơn</Button> */}
                    {/* &nbsp; &nbsp; */}
                    <Button variant="contained" size="small" color="primary" className={classes.margin} onClick={handlePrint} >In HĐ</Button>
                &nbsp;
                <FormControlLabel
                        control={<Checkbox checked={checked} onChange={handleChange} name="checkedB" color="primary" />}
                        label="Trả thẻ"
                        className={classes.margin1}
                    />
                </div>
                 {/* <div>
                <p style={{ marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}>Giảm: <input type="text" style={{width:"20px"}} onChange={handleDiscount} onKeyDown={(e) => { if (e.key === "Enter") { applydiscount() } }} ></input>%</p>
                <p style={{ marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}>Tổng cộng: {count}</p>
                <p style={{ marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}>Tổng chiết khấu:{sharee} </p>
                </div>  */}
                <div>
                    {checked
                        ? (
                            <div>
                                <p style={{ marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}>Giảm: <input type="text" style={{ width: "20px" }} onChange={handleDiscount} onKeyDown={(e) => { if (e.key === "Enter") { applydiscount() } }} ></input>%</p>
                                <p style={{ marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}>Tổng cộng: {count}</p>
                                <p style={{ marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}>Tiền thẻ: <input defaultValue={count} type='text'style={{width:"70px"}} onChange={handleChangeCard} /> 
                                {isNaN(card)
                                ?(<p style={{color:'red'}}>{errorMsgCardInput}</p>)
                                :(<p></p>)}
                                {card > count
                                ?(<p style={{color:'red'}}>{errorMsgNumberbigger}</p>)
                                :(<p></p>)
                                }</p>
                                {cashh >= 0 && <p style={{ marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}>Tiền mặt: {cashh} </p> }
                                
                                <p style={{ marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}>Tổng chiết khấu:{sharee} </p>
                            </div>
                        )
                        : (
                            <div>
                                <p style={{ marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}>Giảm: <input type="text" style={{ width: "20px" }} onChange={handleDiscount} onKeyDown={(e) => { if (e.key === "Enter") { applydiscount() } }} ></input>%</p>
                                <p style={{ marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}>Tổng cộng: {count}</p>
                                <p style={{ marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}>Tổng chiết khấu:{sharee} </p>
                            </div> 
                    )
                    }
                </div>

                {nhoadon.map((hoa, index) => (
                    <div key={index} className={classes.root2} >

                        <List>
                            <ListItemText >
                                {/* <div className="firsline"> */}
                                <table id="receipt">
                                    <tr>
                                        <th className="short">Dịch vụ</th>
                                        <th><div style={{ display: 'flex', justifyContent: 'space-between' }}><p style={{ textAlign: 'center', alignSelf: 'center', margin: '0px' }}>{hoa.name}</p> <button style={{ textAlign: 'right' }} onClick={() => { minusbadge(hoa, index) }} ><CloseIcon color="secondary" /></button></div></th>
                                    </tr>
                                    {/* {show ? */}
                                        <tr>
                                            <td className="short">Giá</td>
                                            <td>
                                                {hoa.price} &nbsp; <input className="newinputdec" type="text" onChange={handleChange1} onKeyDown={(e) => { if (e.key === "Enter") { handlePrice(hoa, index) } }}></input>  
                                                {/* <IconButton size="small" color="primary" onClick={shownhide} ><EditIcon fontSize="small" /></IconButton> */}
                                            </td>
                                        </tr>
                                        {/* : null
                                    }
                                    {hide ? */}
                                        {/* <tr>
                                            <td className="short">Giá</td>
                                            <td><input type="text" onChange={handleChange1} onKeyDown={(e) => { if (e.key === "Enter") { handlePrice(hoa, index) } }} defaultValue={hoa.price}></input></td>
                                        </tr>
                                        : null
                                    } */}
                                    {/* {show ?
                                        <tr>
                                            <td className="short" style={{ width: "auto", maxWidth: "30%" }}>Chính</td>
                                            <td style={{ width: "auto", maxWidth: "70%" }}>
                                                {hoa.chietkhau.map((doer, dex) => (
                                                    <FormControl key={dex} className={classes.formControl} >
                                                        <div style={{ display: 'flex' }}>
                                                            <Select
                                                                variant='standard'
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={doer.name}
                                                                onChange={(e) => { handleChangeDoer(e, index, dex) }}
                                                            >
                                                                {employ.map(em => (
                                                                    <MenuItem value={em.name}>{em.name}</MenuItem>
                                                                ))}
                                                            </Select>
                                                            <p style={{ margin: '0px', paddingTop: '2px' }}>
                                                                {doer.tienchietkhau}
                                                            </p>
                                                        </div>
                                                    </FormControl>

                                                ))}
                                                <IconButton size="small" variant="contained" onClick={() => { moreDoer(index) }} ><AddCircleOutlineRoundedIcon color="primary" fontSize="small" /></IconButton>
                                            </td>
                                        </tr>
                                        : null} */}

                                    {/* hide view */}
                                    {/* {hide ? */}
                                        <tr>
                                            <td className="short" style={{ width: "auto", maxWidth: "30%" }}>Chính</td>
                                            <td style={{ width: "auto", maxWidth: "70%" }}>
                                                {hoa.chietkhau.map((doer, dex) => (
                                                    <FormControl key={dex} className={classes.formControl} >
                                                        <div style={{ display: 'flex' }}>
                                                            <Select
                                                                variant='standard'
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={doer.name}
                                                                onChange={(e) => { handleChangeDoer(e, index, dex) }}
                                                            >
                                                                {employ.map(em => (
                                                                    <MenuItem key={em.toString()} value={em.name}>{em.name}</MenuItem>
                                                                ))}
                                                            </Select>
                                                            <p style={{ margin: '0px', paddingTop: '2px' }}>
                                                                {doer.tienchietkhau} <input className="newinputdec" id="sharemoney" style={{ width: "30px" }} type="text" onChange={handleChange2} onKeyDown={(e) => { if (e.key === "Enter") { handleshare(index, dex) } }} ></input>
                                                            </p>
                                                        </div>
                                                    </FormControl>

                                                ))}
                                                <IconButton size="small" variant="contained" onClick={() => { moreDoer(index) }} ><AddCircleOutlineRoundedIcon color="primary" fontSize="small" /></IconButton>
                                            </td>
                                        </tr>
                                        {/* // : null} */}
                                    {/* end hide */}
                                    <tr>
                                        <td className="short">Phụ</td>
                                        <td>
                                            {hoa.help.map((doer, dex) => (
                                                <FormControl key={dex} className={classes.formControl}>
                                                    <div style={{ display: 'flex' }}>
                                                        <Select
                                                            variant='standard'
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={doer.name}
                                                            onChange={(e) => { handleChangeHelper(e, index, dex) }}
                                                        >
                                                            {employ.map(em => (
                                                                <MenuItem value={em.name}>{em.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        <p style={{ margin: '0px', paddingTop: '2px' }}>
                                                            {doer.tienchietkhau}
                                                        </p>
                                                    </div>
                                                </FormControl>
                                            ))}
                                            <IconButton size="small" variant="contained" onClick={() => { moreHelper(index) }} ><AddCircleOutlineRoundedIcon color="primary" fontSize="small" /></IconButton>
                                        </td>
                                    </tr>

                                </table>
                                {/* </div> */}
                            </ListItemText>
                        </List>
                    </div>
                ))}

            </div>

        </div>
        <div style={{ display: "none" }}>
            <ComponentToPrint ref={componentRef} />
            <ComponentToPrintEnDay ref={componentRefEnd} />
            <ComponentToPrintShare ref={componentRefShare} /> 
            <ComponentToPrintMomo ref={componentToPrintMomo} />
            <ComponentToPrintSacombank ref={componentToPrintSacombank}/>
                                                
        </div>
        {/* PHẦN NÀY CỦA TABLE */}
        <ScrollToTop />
        <div>
            <HistoryTable samedayreport={samedayreport} samedayhis={samedayhis} totalRev={totalRev} totalShare={totalShare} />
            
        </div>
    </div>
}


class ComponentToPrint extends React.Component {

    render() {

        return (
            <div id="receipt" style={{ width: "44mm", height: "fit-content", margin: "0px", padding: "0px" }}>
                <div style={{ textAlign: "center", marginBottom: "4px" }}>
                    <img alt="logobill.png" src={process.env.PUBLIC_URL + "/images/logobill2.png"} style={{ width: "170px" }} />
                </div>
                <div style={{ height: "fit-content" }}>
                    <h5 style={{ textAlign: "center", fontSize: "10px" }}>
                        {/* 612-614 LÊ ĐỨC THỌ, P.15, G.VẤP<br></br>
                        HOTLINE: 090.138.4031 <br></br> */}
                        169 Nguyễn Văn Nghi, P.7, G.VẤP <br></br>
                        HOTLINE: 090 263 73 53<br></br>
        
                    </h5>
                    <TimeReceipt />
                    <table id="tablereceipt">
                        <thead>
                            <tr>
                                <th style={{ fontSize: "13px" }}>Dịch vụ</th>
                                <th style={{ fontSize: "13px" }}>Giá</th>
                            </tr>
                        </thead>
                        {dv.map((hoa, index) => (
                            <tbody>
                                <tr>
                                    <td style={{ fontSize: "13px" }}>{hoa.name}</td>
                                    <td style={{ fontSize: "13px" }}>{numberWithCommas(hoa.price).concat(".000")}</td>
                                </tr>
                            </tbody>
                        ))}
                    </table>

                    <h4 style={{ marginLeft: "5px", fontSize: "15px" }}>Tổng cộng: {tong} </h4>

                    <h4 style={{ textAlign: "center", fontSize: "15" }} ><em>Cảm ơn quý khách</em></h4>
                </div>

            </div>
        )
    }
}


//in momo
class ComponentToPrintMomo extends React.Component {

    render() {

        return (
            <div id="receipt" style={{ width: "44mm", height: "fit-content", margin: "0px", padding: "0px",textAlign:"center" }}>
                <TimeReceipt />
                <div style={{ textAlign: "center", marginBottom: "4px" }}>
                    <h4 style={{ textAlign: "left", fontSize: "11px" }}>
                        TEN: PHAM QUACH TAN PHAT<br></br><br></br>
                        SĐT: 098.501.2420 <br></br><br></br>
                        MOMO:
                        <img alt="momo" src={process.env.PUBLIC_URL + "images/momocode.jpg"} style={{width:"170px"}}/>
                    </h4>
                    
                    {/* <h4 style={{ textAlign: "center", fontSize: "15" }} ><em>Cảm ơn quý khách</em></h4> */}
                </div>
            </div>
        )
    }
}
//in sacombank
class ComponentToPrintSacombank extends React.Component {

    render() {

        return (
            <div id="receipt" style={{ width: "44mm", height: "fit-content", margin: "0px", padding: "0px",textAlign:"center" }}>
                <TimeReceipt />
                <div style={{ textAlign: "center", marginBottom: "4px" }}>
                    <h4 style={{ textAlign: "left", fontSize: "11px" }}>
                        Chủ tài khoản: PHAM QUACH TAN PHAT
                        Số tài khoản: 27057647
                        {/* <img alt="acb" src={process.env.PUBLIC_URL + "images/qrsacombank.jpg"} style={{width:"170px"}}/> */}
                    </h4>
                </div>
            </div>
        )
    }
}

class ComponentToPrintEnDay extends React.Component {
    render() {
        return (
            <div style={{ width: "44mm", height: "fit-content", margin: "0px", padding: "0px" }}>
                <h2 style={{ textAlign: "center" }}>DŨ SALON</h2>
                <h3 style={{ margin: "5px 5px" }}>{ngay} </h3>

                    <table id="tablereceipt">
                        <tbody>
                        <tr >
                            <th>Thu</th>
                            <th>{tongngay}</th>
                        </tr>
                        <tr >
                            <td>Tiền mặt</td>
                            <td>{tienmat}</td>
                        </tr>
                        <tr>
                            <td>Thẻ</td>
                            <td>{chuyenkhoan}</td>
                        </tr>
                        <tr>
                            <th>Chiết khấu</th>
                            <th>{tongchietkhau} </th>
                        </tr>
                        {danhsachchietkhau.map(rep => (
                            <tr key={rep.id}>
                                <td>{rep.name} </td>
                                <td>{rep.sale} </td>
                            </tr>
                        ))}
                        <tr>
                            <th>Chi</th>
                            <th>{tongchi} </th>
                        </tr>

                        {danhsachchi.map(item => (
                            <tr key={item.createdAt}>
                                <td>{item.category} </td>
                                <td>{item.price} </td>
                            </tr>
                        ))}

                        <tr>
                            <th>Còn lại</th>
                            <th>{tongngay - chuyenkhoan - tongchi - tongchietkhau} </th>
                        </tr>
                        </tbody>
                    </table>

            </div>
        )
    }
}

class ComponentToPrintShare extends React.Component {
    render() {
        return (
            <div style={{ width: "44mm", height: "fit-content", margin: "0px", padding: "0px" }}>
                <h2 style={{ textAlign: "center" }}>DŨ SALON</h2>

                {chietkhaunv.map(dayend => (
                    <div key={dayend.toString()}>
                        <p style={{ width: "95%", margin: "2px 2px" }}>Ngày: {ngay} </p>
                        <p style={{ width: "95%", margin: "2px 2px" }}>Tên: {dayend.name} </p>
                        <p style={{ width: "95%", margin: "2px 2px" }}>Tiền chiết khấu: {dayend.sale} </p>
                        <table id="tablereceipt">
                            <tbody>
                            <tr>
                                <th>DV</th>
                                <th>GIÁ</th>

                                <th>CK</th>
                            </tr>
                            {dayend.services.map(service => (
                                <tr key={service.time+service.sale}>
                                    <td>{service.name} </td>
                                    <td>{service.price} </td>
                                   
                                    <td>{service.sale} </td>
                                </tr>
                            ))}
                            {dayend.servicess.map(service => (
                                <tr key={service.time+service.sale}>
                                    <td>{service.name} </td>
                                    <td>{service.price} </td>
                                    
                                    <td>{service.sale} </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
        )
    }
}


