import React,{useCallback,useContext} from 'react'
import {withRouter,Redirect} from 'react-router'
import app from '../Config/firebase.config'
import {AuthContext} from './Auth'
import Button from '@material-ui/core/Button';

const Login = ({history}) =>{
    const handleLogin = useCallback (
        async event =>{
            event.preventDefault();
            const {email,password} =event.target.elements;
            try{
                await app
                .auth()
                .signInWithEmailAndPassword(email.value,password.value);
                history.push("/");
            } catch (error){
                alert(error);
            }
        },[history]
    );
    const {currentUser} = useContext(AuthContext);
    if(currentUser){
        return <Redirect to="/" />
    }

    return(
        <div className="login">
            <form onSubmit={handleLogin} className="loginform" >
                <h1 style={{textAlign:"center",color:"rgb(145, 109, 63)",backgroundColor:"whitesmoke"}}>ĐĂNG NHẬP</h1>
                <div className="input">
                    <div className="label">
                        Tên 
                    </div> 
                    <input className="inputlogin"  id="email" name="email" type="email" placeholder="Tên đăng nhập..." />

                </div>
                <div className="input">
                    <div className="label">
                        Mật khẩu
                    </div>
                    <input className="inputlogin" id="password" name="password" type="password" placeholder="Mật khẩu..." />
                
                </div>
                <div style={{textAlign:"center"}}>
                <Button variant="contained" type="submit">Log in</Button>
                </div>
                
            </form>
        </div>    
    );
};

export default withRouter(Login);

